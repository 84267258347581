import React from 'react';

 
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import { createUseStyles } from 'react-jss';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import {DefaultButton,ButtonContainer,DefaultBackButton}  from '../StylesUI/CommonLayouts';
import { Layout } from '../Layout';
import * as common from "../../common";
import { ExamTable } from '../Table/ExamTable';
import { Exam ,APIResponse ,Learner} from '../../react-app-env';
import {normalLinks,goExamRegister} from '../NavLinks/NavLinks';

// 引数で生成するCSSを定義してuseStyles関数を生成
const useStyles = createUseStyles({
    // 第一階層はCSSクラス名（実際は後ろに重複防止の接尾辞がつく）
    // homeRoot => .homeRoot-1-2-3 など
    examIndexRoot: {
        fontSize: common.FONT_SIZE.mainText,
        // 入れ子にする場合は＆を使う
        // https://cssinjs.org/jss-plugin-nested?v=v10.4.0
        "& h1": {
            color: "green"
        },
        "& .xxxxx": {
            color: "green"
        },
        "& h4": {
            color: "#003f71",
            fontWeight: "bold",
            marginBottom:"30px",
            fontSize:common.FONT_SIZE.title
        },
        "& h5": {
            color: "#003f71",
            fontWeight: "bold",
            fontSize:common.FONT_SIZE.mainText,
            margin:0
        },
        "& .MuiOutlinedInput-notchedOutline": {
            height:"50px"
        },
        "& .MuiOutlinedInput-input":{
            padding: "15.5px 14px"
        },
        "& button":{
            fontSize: common.FONT_SIZE.button,
        },
        "& button:focus": {
            outline:"none !important", 
        },
        "& input , .mainTextSize, .MuiMenuItem-root":{
            fontSize: common.FONT_SIZE.mainText,
        }
    },
    mainTextSize:{
        fontSize: common.FONT_SIZE.mainText,
        "& .MuiMenuItem-root":{
            fontSize: common.FONT_SIZE.mainText,
        },
        "& svg":{
            fontSize:"28px"
        }
    },
    upperContents: {
        display: "flex",
        justifyContent: "space-between"
    },
    examListContainer: {
        position: "relative"
    },
    searchContainer: {
        display:"flex",
        justifyContent: "space-around",
        alignItems:"center",
        "& #year":{
            width:"5rem"
        },
        "& #month":{
            width:"3rem"
        }
    },
    dropDown: {
        width:"32px"
    },
    buttonStyle: {
        marginTop:"3.2rem",
        "& button:hover": {
            backgroundColor:"#003f71", 
        },
        "& button:focus": {
            outline:"none !important", 
        },
        "& .backBtn:hover": {
            backgroundColor:"#003f71",
            color:"white" 
        },
        "& .MuiSvgIcon-root":{
            fontSize:"1.8rem"
        }
    },
    checkBoxContainer:{
        display:"flex",
        alignItems:"center",
        fontSize:common.FONT_SIZE.mainText,
        "& svg":{
            fontSize:28
        },
        "& span":{
            paddingLeft:"0"
        }
    }
});



export interface transitExamValue { 
    pathname: string,
    state :  Exam
} ;



export const initialExamStateValue = {
    id:0,
    name:"" ,
    status:0,
    termType:0,
    shuffleFlag:false,
    numberingType:0,
    acceptStart:"",
    acceptEnd:"",
    executeStart:"" ,
    executeEnd:"",
    executeTime:1,
    learnerMax:1,
    guidanceUrl:"",
    startViewFlag:false,
    startBodyHtml:"",
    endViewFlag:false ,
    endBodyHtml:"",
    allPoints:0,
    passPoints:0,
    deleteFlag:false,
    modified:new Date(),
    choiceDeviceFlag:false,
    afterReadFlag:false,
    acceptExpire:0,
    advanceExplanationVideoUrl:""
};




export function ExamIndex() {

    // -----API-----
    // 試験データを取得する
    function getExamData(args?: any):Promise<APIResponse<Exam[]>> {
        return api("/api/o-exam", "GET", args)
    }


    // 受験者データを取得する
    function getLearnerData(args?: any):Promise<APIResponse<Learner[]>> {
        return api("/api/o-learner", "GET", args)
    }


    // -----共通関数の宣言-----
    const {
        go, // 画面遷移 
        api  // API呼び出し
    } = common.useCommon();
    


    // -----Handler-----
    
    const setYearMonthState = (event: React.ChangeEvent<{ name?: string; value: any }>) => {
        const name = event.target.name as keyof typeof state;
        setState({
            ...state,
            [name]: event.target.value,
        });
    };


    const handleClickSortOrder = ()=>{
        if(sortOrder === "ASC") setSortOrder("DESC");
        else setSortOrder("ASC");
    }

    //終了試験を含むかの切り替え
    const switchIncludingTested =()=>{

        if(containsFinished){
            setExamSearchData(removeTested(examSearchData));
            setContainsFinished(false);
        }else{
            setExamSearchData(yearMonthSearch(examData ,state.year ,state.month));
            setContainsFinished(true);
        }
    }


    //終了後のテストを除外する
    const removeTested = (examArray:Exam[])=>{
        return examArray.filter((exam:Exam)=>{
            const tested_time = new Date(exam.executeEnd).getTime();
            const now_time = new Date().getTime();

            if(now_time < tested_time) return true;
            else return false
        })
    }





    //検索の実行 
    const selectSearchYearMonth=(event: React.ChangeEvent<{ name?: string; value: any }>)=>{
        event.preventDefault();
        setYearMonthState(event);

        const searchData = containsFinished ? examData : removeTested(examData);
        switch (event.target.name) {
            case "year":
                setExamSearchData(yearMonthSearch( searchData , event.target.value ,state.month));
                break;
        
            case "month":
                setExamSearchData(yearMonthSearch( searchData , state.year ,event.target.value));
                break;
        }
        
        
    };


    //年月での検索
    function yearMonthSearch(searchData:Exam[] ,year:number ,month:number){

        if( year===0 &&  month===0) return searchData
        else if( year!=0 &&  month===0){

            return searchData.filter((exam:Exam)=>{
                const yearMonthData=exam.executeStart.split('-');
                return (Number(yearMonthData[0])=== year) 
            })

        }else if( year===0 &&  month!=0){

            return searchData.filter((exam:Exam)=>{
                const yearMonthData=exam.executeStart.split('-');
                return (Number(yearMonthData[1])=== month) 
            })

        }else{
            return searchData.filter((exam:Exam)=>{

                const yearMonthData=exam.executeStart.split('-');
                return (Number(yearMonthData[0])=== year && Number(yearMonthData[1])=== month) 

            })
        }
    };


 

    //表示中の試験に含まれる試験開始年度を抽出
    function searchIncludedYear(data:Exam[]){

        const newIncludedYear=includedYear.concat();

        for(const exam of data){

            const year=exam.executeStart.split('-');

            if(!newIncludedYear.includes(year[0])){
                newIncludedYear.push(year[0])
            } 

        }
        setIncludedYear(newIncludedYear)
    };



    //指定された試験の現状の登録受験者数を返す
    const getNumberOfLearner = (id:number)=>{

        getLearnerData({executionId:id})
        .then((data:APIResponse<Learner[]>) => {

            if (data.errorCode !== 20000) {
                common.alertError(data.errorTitle , data.errorDetail);
                return;
            }

            if(data.value !== null) setNumberOfLearner( data.value.length )
           
        }).catch((err) => {
            alert(common.ResponseMessages.Error_GetLearner);
        });
    }



    //試験データの並び順を決める
    function switchOrder(examArray:Exam[]){
        let retVal:Exam[] = [];

        switch (sortOrder) {
            case "ASC":
                retVal = ascSortExam(examArray);
                break;
        
            case "DESC":
                retVal = descSortExam(examArray);
                break;
        }

        return retVal;

    }



    //降順ソート
    function descSortExam(examArray:Exam[]){
        return examArray.sort(function(a:Exam,b:Exam){
          const aTime = new Date(a.executeStart).getTime();
          const btime = new Date(b.executeStart).getTime();
  
          if(aTime > btime){
            return -1 ;
          }
          if(aTime < btime){
            return 1 ;
          }
          return 0;
  
        })
  
      }
  
    //昇順ソート
    function ascSortExam(examArray:Exam[]){
      return examArray.sort(function(a:Exam,b:Exam){
        const aTime = new Date(a.executeStart).getTime();
        const btime = new Date(b.executeStart).getTime();

        if(aTime < btime){
          return -1 ;
        }
        if(aTime > btime){
          return 1 ;
        }
        return 0;

      })

    }

    function orderByDesc(a:string,b:string){
        if( Number(a) > Number(b) ) return -1;
        if( Number(a) < Number(b) ) return 1;
        return 0;
    };




    // -----スタイルの宣言-----
    const classNames = useStyles();


    

    
    // -----state-----
    //検索年月
    const [state, setState] = React.useState<{ year: number ; month: number ;}>({
        year: 0,
        month: 0,
    });
    const [examData, setExamData] = React.useState<Exam[]>([]);
    const [examSearchData, setExamSearchData] = React.useState<Exam[]>([]);
    const [containsFinished,setContainsFinished] = React.useState(false);
    const [includedYear,setIncludedYear]=React.useState<string[]>([]);

    const [numberOfLearner, setNumberOfLearner] = React.useState(0);
    const [isLoaded,setIsLoaded] = React.useState(false);
    const [sortOrder,setSortOrder] = React.useState("DESC");


    const yearMonthSelectForm = 
        <div className={classNames.searchContainer} >
            <h5>試験開始年月</h5>　
            <Select
                //native
                value={state.year}
                onChange={selectSearchYearMonth}
                label="year"
                inputProps={{
                    name: 'year'
                }}
                className={classNames.mainTextSize}
                id="year"
            >
                <MenuItem aria-label="None" value={0}>　</MenuItem>
                {includedYear.sort(orderByDesc).map(data=>(
                    <MenuItem style={{fontSize:common.FONT_SIZE.mainText}} key={data} value={Number(data)}>{data}</MenuItem>
                ))}
            </Select><h5>年</h5>　
            <Select
                //native
                value={state.month}
                onChange={selectSearchYearMonth}
                label="month"
                inputProps={{
                    name: 'month',
                    //id: 'outlined-age-native-simple',
                }}
                className={classNames.mainTextSize}
                id="month"
            >
                <MenuItem aria-label="None" value={0}>　</MenuItem>
                <MenuItem style={{fontSize:common.FONT_SIZE.mainText}} value={1}>1</MenuItem>
                <MenuItem style={{fontSize:common.FONT_SIZE.mainText}} value={2}>2</MenuItem>
                <MenuItem style={{fontSize:common.FONT_SIZE.mainText}} value={3}>3</MenuItem>
                <MenuItem style={{fontSize:common.FONT_SIZE.mainText}} value={4}>4</MenuItem>
                <MenuItem style={{fontSize:common.FONT_SIZE.mainText}} value={5}>5</MenuItem>
                <MenuItem style={{fontSize:common.FONT_SIZE.mainText}} value={6}>6</MenuItem>
                <MenuItem style={{fontSize:common.FONT_SIZE.mainText}} value={7}>7</MenuItem>
                <MenuItem style={{fontSize:common.FONT_SIZE.mainText}} value={8}>8</MenuItem>
                <MenuItem style={{fontSize:common.FONT_SIZE.mainText}} value={9}>9</MenuItem>
                <MenuItem style={{fontSize:common.FONT_SIZE.mainText}} value={10}>10</MenuItem>
                <MenuItem style={{fontSize:common.FONT_SIZE.mainText}} value={11}>11</MenuItem>
                <MenuItem style={{fontSize:common.FONT_SIZE.mainText}} value={12}>12</MenuItem>
            </Select><h5>月</h5>
        </div>




    // -----use effefct-----
    // 初回だけ実行する処理
    React.useEffect(() => {
        // サーバーから認証してデータ取得
        getExamData()
            .then((data:APIResponse<Exam[]>) => {

                if (data.errorCode !== 20000) {
                    common.alertError(data.errorTitle , data.errorDetail);
                    return;
                }

                if(data.value !== null){

                    const examData = data.value.filter((exam:Exam)=>{
                        return (exam.deleteFlag===false) 
                    })
    
                    setExamData(examData);
                    setExamSearchData(removeTested(examData));
                    searchIncludedYear(examData);
                    setIsLoaded(true);
                }


            }).catch((err) => {
                alert(common.ResponseMessages.Error_GetExam);
            });


            
    }, []);
 
    

    
    return (
        <Layout navLinks={normalLinks} buttons={
            [
              { text: goExamRegister.text, handler: () => { go({pathname:goExamRegister.pathname,state:goExamRegister.state })} }
            ]
          }>
            {isLoaded ?

                <div className={classNames.examIndexRoot}>
                    
                    <div className={classNames.upperContents}>

                        <div>
                            <h4>試験一覧</h4>
                        </div>

                    </div>

                    <div className={classNames.examListContainer}>

                        <ButtonContainer className={classNames.buttonStyle} style={{marginBottom:"1.2rem"}}>
                            <DefaultBackButton className="backBtn" onClick={() => { go("/") }}><ArrowBackIosIcon /><span>&nbsp;戻る</span></DefaultBackButton>
                            <DefaultButton  onClick={() => {go({pathname:"/exam/register/0" }) }}><AddCircleOutlineIcon /><span>&nbsp;新規登録</span></DefaultButton>
                        </ButtonContainer>

                        <ExamTable containsFinished={containsFinished} yearMonthSelectForm={yearMonthSelectForm} sortOrder={sortOrder} handleClickSortOrder={handleClickSortOrder} switchOrder={switchOrder} examData={examSearchData} getNumberOfLearner={getNumberOfLearner} numberOfLearner = {numberOfLearner} switchIncludingTested={switchIncludingTested}/>

                    </div>

                    <ButtonContainer className={classNames.buttonStyle}>
                        <DefaultBackButton className="backBtn" onClick={() => { go("/") }}><ArrowBackIosIcon /><span style={{paddingBottom:"0.2rem"}}>&nbsp;戻る</span></DefaultBackButton>
                        <DefaultButton  onClick={() => {go({pathname:"/exam/register/0" }) }}><AddCircleOutlineIcon /><span style={{paddingBottom:"0.2rem"}}>&nbsp;新規登録</span></DefaultButton>
                    </ButtonContainer>

                </div>

            : <div>Loading...</div>

            }

        </Layout>
    );
}


import { LocationDescriptorObject, History } from "history";
import { useContext } from 'react';
import { useHistory, useParams } from "react-router-dom";
import { AppContext } from "./App";
import { ApplicationPaths, QueryParameterNames } from './components/api-authorization/ApiAuthorizationConstants';
import authService from './components/api-authorization/AuthorizeService';
import { APIArgs, ApiMethod, UserData } from "./react-app-env";
import moment from "moment";
import { MomentInput } from "moment";
import 'moment/locale/ja';
import neatCsv from "neat-csv";
import React from "react";

export const APP_DISP_NAME = "リモテスト";

const logoutPath = { pathname: `${ApplicationPaths.LogOut}`, state: { local: true } };

export const FONT_SIZE = { title:24 ,tableHeader:"1.7rem" ,tableBody:"1.6rem" ,tablePagenation:"1.4rem" ,mainText:"1.6rem" ,button:"1.6rem" ,sideBarText:"1.4rem"
                           ,sideBarBtn:"0.7rem" ,richEditorRoot:"1.7rem" ,richEditorText:"1.6rem",choiceText:"1.8rem"}

//APIの応答メッセージ
export const ResponseMessages = {
    //-----/api/o-user------------
    Success_PostAdminUser:"主催者ユーザ情報を登録しました",
    Success_PutAdminUser:"主催者ユーザ情報を更新しました",
    Success_DisableAdminUser:"主催者ユーザを無効化しました",
    Success_EnableAdminUser:"主催者ユーザを有効化しました",
    Error_GetAdminUser:"主催者ユーザ情報の取得に失敗しました",
    Error_PostAdminUser:"主催者ユーザ情報の登録に失敗しました",
    Error_PutAdminUser:"主催者ユーザ情報の更新に失敗しました",
    Error_DisableAdminUser:"主催者ユーザ情報の無効化に失敗しました",
    Error_EnableAdminUser:"主催者ユーザ情報の有効化に失敗しました",
    
    //-----/api/o-learner------------
    Sucess_PostLearner:"受験者情報を登録しました",
    Sucess_PutLearner:"受験者情報を更新しました",
    Sucess_DeleteLearner:"受験者情報を削除しました",
    Error_GetLearner:"受験者情報の取得に失敗しました",
    Error_PostLearner:"受験者情報の登録に失敗しました",
    Error_PutLearner:"受験者情報の更新に失敗しました",
    Error_DeleteLearner:"受験者情報の削除に失敗しました",
    
    //-----/api/o-exam------------
    Sucess_PostExam:"試験情報を登録しました",
    Sucess_PutExam:"試験情報を更新しました",
    Sucess_DeleteExam:"試験情報を削除しました",
    Error_GetExam:"試験情報の取得に失敗しました",
    Error_PostExam:"試験情報の登録に失敗しました",
    Error_PutExam:"試験情報の更新に失敗しました",
    Error_DeleteExam:"試験情報の削除に失敗しました",
    
    //-----/api/o-learnerCSV------------
    Error_PostLearnerCSV:"受験者情報のアップロードに失敗しました",
    
    //-----/api/o-question------------
    Error_GetQuestion:"試験問題の取得に失敗しました",
    Error_PostQuestion:"試験問題の保存に失敗しました",
    
    //-----/api/o-userPassword------------
    Sucess_PutUserPassword:"パスワードの変更に成功しました",
    Error_PutUserPassword:"パスワードの変更に失敗しました",
}



/**
 * 時間のフォーマット
format('LT');   // 11:31
format('LTS');  // 11:31:44
format('L');    // 2020/10/26
format('l');    // 2020/10/26
format('LL');   // 2020年10月26日
format('ll');   // 2020年10月26日
format('LLL');  // 2020年10月26日 11:31
format('lll');  // 2020年10月26日 11:31
format('LLLL'); // 2020年10月26日 月曜日 11:31
format('llll'); // 2020年10月26日(月) 11:31
https://momentjs.com/docs/#/displaying/
 * @param d 
 * @param format 
 */
export function dateFormat(d: MomentInput, format: string) {
    return moment(d).format(format);
}

/**
 * csvパーサー
 * オプションの詳細は以下参照
 * 例：headersに文字列の配列を指定でヘッダー指定、falseでヘッダー行なし
 * https://github.com/mafintosh/csv-parser#options
 * @param data 
 * @param options 
 */
export function parseCsv<Row = neatCsv.Row>(data: string | Buffer, options?: neatCsv.Options) {
    return neatCsv<Row>(data, options);
}

function toCsvLineItem(s: string) {
    // "null" "undefined" に変換されるのを回避する
    if (s === null || s === undefined) {
        s = "";
    }
    // それ以外は既定の文字列変換後にエスケープ処理
    const v = String(s).replace(`"`, `""`);
    // 引用符で囲んで返す
    return `"${v}"`;
}

/**
 * CSV変換
 * @param rows 
 * @param option noHeaderでヘッダー出力なし、colsで出力する列と順序を指定
 */
export async function toCsv(rows: { [key: string]: string }[], option?: {
    noHeader?: boolean;
    cols?: string[]
}) {

    if (typeof rows !== 'object' || Array.isArray(rows) !== true) {
        return Promise.reject("引数が配列ではありません");
    }

    return Promise.resolve()
        .then(() => {

            let cols: string[];
            if (option?.cols) {
                // 指定されていれば指定された項目を出力
                cols = option.cols;
            } else {
                // 全ての行の全てのキーを取得
                const allKeys = new Set<string>();
                rows.forEach((row) => {
                    Object.keys(row).forEach((key) => {
                        allKeys.add(key);
                    });
                });
                cols = Array.from(allKeys).sort();
            }

            const bodyLines = rows.map((row) => {
                return cols.map((col) => {
                    return toCsvLineItem(row[col]);
                }).join(",");
            }).join("\n");

            if (option?.noHeader) {
                return bodyLines;
            } else {
                const headerLine = cols.map(toCsvLineItem).join(",");
                return headerLine + "\n" + bodyLines;
            }
        });
}

/**
 * utf-8 BOM 付きでファイルを保存する
 * @param content 
 * @param fileName 
 */
export function saveTextFile(content: string, fileName: string) {
    const encoder = new TextEncoder();
    // BOMを付加する
    const buffer = encoder.encode('\ufeff' + content);
    const blob = new Blob([buffer], { type: "text/plain;charset=utf-8" });
    if (window.navigator.msSaveBlob) {
        window.navigator.msSaveBlob(blob, fileName);
    } else {
        var a = document.createElement("a");
        a.href = URL.createObjectURL(blob);
        //a.target   = '_blank';
        a.download = fileName;
        document.body.appendChild(a) //  FireFox specification
        a.click();
        document.body.removeChild(a) //  FireFox specification
    }
}

/**
 * テキストファイルを読む
 * @param file 
 * @param encoding 
 */
export function readTextFile(file: Blob, encoding: string = "utf-8") {
    return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const decoder = new TextDecoder(encoding)
            const buffer = e.target?.result as ArrayBuffer;
            const string = buffer ? decoder.decode(buffer) : "";
            resolve(string);
        }
        reader.onabort = (e) => { reject(e); };
        reader.onerror = (e) => { reject(e); };
        reader.readAsArrayBuffer(file);
    });
}

export function useCommon() {
    const history = useHistory();
    const params = useParams<{ [key: string]: string }>();
    const { appContext, setAppContext } = useContext(AppContext);
    const withLoading = <T = any>(p: Promise<T>) => {
        setAppContext((c) => ({ backdropIsopen: true }));
        return p.then((res) => {
            setAppContext((c) => ({ backdropIsopen: false }));
            return res;
        }, (err) => {
            setAppContext((c) => ({ backdropIsopen: false }));
            return Promise.reject(err);
        });
    }
    return {
        history: history,
        params: params,
        appContext: appContext,
        setAppContext: setAppContext,
        getUser: async () => (authService.getUser() as Promise<UserData>),
        go: (path: string | LocationDescriptorObject) => history.push(path),
        logout: () => history.push(logoutPath),
        withLoading: withLoading,
        api: async <TArgs = APIArgs, TResponse = any>(path: string, method: ApiMethod, args?: TArgs) => {
            // expired時に再ログインする
            const expired = await checkExpiredAndRedirect(history);
            if (expired) {
                return {} as TResponse;
            }
            return withLoading(api<TArgs, TResponse>(path, method, args));
        }
    };
}

async function checkExpiredAndRedirect(history: History) {
    const oUser = await authService.userManager?.getUser();
    if (oUser && oUser.expired) {
        var link = document.createElement("a");
        link.href = window.location.href;
        const returnUrl = `${link.protocol}//${link.host}${link.pathname}${link.search}${link.hash}`;
        const redirectUrl = `${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${encodeURI(returnUrl)}`;
        history.push(redirectUrl);
        return true;
    }
    return false;
}

function toQuery(args: any) {
    const parts: string[] = [];
    for (let p in args) {
        try {
            const v = args[p];
            parts.push(encodeURIComponent(p) + "=" + encodeURIComponent(v));
        } catch (e) {
            console.log(e);
        }
    }
    return "?" + (parts.join("&"));
}

/**
 * API呼び出しの本体
 * @param path 
 * @param method 
 * @param args 
 */
async function api<TArgs = APIArgs, TResponse = any>(path: string, method: ApiMethod, args?: TArgs) {
    const token = await authService.getAccessToken();
    const headers = new Headers({
        "Content-Type": "application/json; charset=utf-8"
    });
    if (token) {
        headers.append('Authorization', `Bearer ${token}`);
    } else {
        console.warn("no token");
    }

    const hasQuery = args && (method === "GET" || method === "DELETE");
    const query = hasQuery ? toQuery(args) : "";

    const hasBody = args && (method === "POST" || method === "PUT" || method === "PATCH");
    const body = hasBody ? JSON.stringify(args) : undefined;

    const response = await fetch(path + query, {
        method: method,
        cache: "no-cache",
        credentials: 'same-origin', // include, *same-origin, omit
        headers: headers,
        body: body
    });
    if (!response.ok) {
        return Promise.reject(`error status code : ${response.status} ${response.statusText}`);
    }
    return await response.json() as Promise<TResponse>;
}

//////////////////////////////////////////////
//バリデーション関数
//////////////////////////////////////////////

/**
 * パスワード検証
 * @param password 
 */
export const validatePasswordFunc = (password: string) => {
    let correctFlag = true;
    let errorMessage = "";

    if (password.length < 6 || password.length > 100) {
        errorMessage += "パスワードは最小6文字、最大100文字で入力してください\n"
        correctFlag = false;
    }

    // 数字が存在するかチェック 
    if (password.match(/[0-9]/) === null) {
        errorMessage += "パスワードは数字を最低1文字含めてください\n"
        correctFlag = false;
    }

    // 英大文字が存在するかチェック 
    if (password.match(/[A-Z]/) === null) {
        errorMessage += "パスワードは英大文字を最低1文字含めてください\n"
        correctFlag = false;
    }

    // 英小文字が存在するかチェック 
    if (password.match(/[a-z]/) === null) {
        errorMessage += "パスワードは英子文字を最低1文字含めてください\n"
        correctFlag = false;
    }

    // 半角記号が存在するかチェック 
    if (password.match(/[!@#$%^&*()_\+\-=\[\]{};:?,.\/\\<>|~`'"]/) === null) {
        errorMessage += "パスワードは英数字以外の文字を最低1文字含めてください"
        correctFlag = false;
    }

    return { correctFlag, errorMessage }
}

/**
 * メールアドレス検証
 * @param email 
 */
export const validateEmailFunc = (email: string) => {
    let correctFlag = true;
    let errorMessage = "";

    if (email.match(/^([a-zA-Z0-9]|[-!#$%&'*+/=?^_`{}|~])+([a-zA-Z0-9\.]|[-!#$%&'*+/=?^_`{}|~])*@([a-zA-Z0-9-])+((\.(?!\.))|[a-zA-Z0-9-]+)+([a-zA-Z0-9-])$/)) {
        errorMessage = "";
        correctFlag = true;
    } else if (email === "") {
        errorMessage = "入力して下さい";
        correctFlag = false;
    } else {
        errorMessage = "正しいメールアドレスを入力して下さい";
        correctFlag = false;
    }

    return { correctFlag, errorMessage }

}

/**
 * 電話番号検証
 * @param phoneNumber 
 */
export const validatePhoneNumberFunc = (phoneNumber: string) => {
    let correctFlag = true;
    let errorMessage = "";

    if (phoneNumber.match(/^0\d{1,4}-?\d{1,4}-?\d{4}$/)) {
        errorMessage = "";
        correctFlag = true
    } else if (phoneNumber === "") {
        errorMessage = "入力して下さい";
        correctFlag = false;
    } else {
        errorMessage = "正しい電話番号を入力して下さい(半角数字)";
        correctFlag = false;
    }

    return { correctFlag, errorMessage }

}

export function alertError(title:string, details:string){
    const ary : string[] = [];
    if(title && title !== "undefined"){
        ary.push(title);
    }
    if(details && details !== "undefined"){
        ary.push(details);
    }
    var message = ary.join("\n");
    if(message.trim().length > 0){
        alert(message);
    } else {
        console.trace("empty alert");
    }
}

export function useInterval(before: Function, callback: () => any, delay: number) {
    const savedCallback = React.useRef<() => any>();
    React.useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);
    React.useEffect(() => {
        function tick() {
            if (savedCallback.current) {
                savedCallback.current();
            }
        }
        if (delay !== null) {
            before();
            const id = setInterval(tick, delay);
            return () => { clearInterval(id); }
        }
    }, [delay]);
}




import { AppBar, BottomNavigation, BottomNavigationAction, FormControl, FormControlLabel, Grid, Paper, Radio, RadioGroup, Tab, Tabs, TextField } from '@material-ui/core';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { useLocation } from "react-router-dom";
import * as common from "../../common";
import { APIResponse, Cheat, CheckCheat, Exam, Learner } from '../../react-app-env';
import { Layout } from '../Layout';
import { normalLinks } from '../NavLinks/NavLinks';
import { CustomModal } from '../StylesUI/CustomModal';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { ButtonContainer, DefaultBackButton } from '../StylesUI/CommonLayouts';

// 引数で生成するCSSを定義してuseStyles関数を生成
const useStyles = createUseStyles({
    // 第一階層はCSSクラス名（実際は後ろに重複防止の接尾辞がつく）
    // homeRoot => .homeRoot-1-2-3 など
    recordingPlaybackRoot: {
        fontSize: common.FONT_SIZE.mainText,
        // 入れ子にする場合は＆を使う
        // https://cssinjs.org/jss-plugin-nested?v=v10.4.0
        "& h1": {
            color: "green"
        },
        "& .xxxxx": {
            color: "green"
        },
        "& h6": {
            color: "#003f71",
            fontWeight: "bold",
            marginBottom: "0px"
        }
    },
    bottomNavigation: {
        "& .MuiBottomNavigationAction-label": {
            color: "black",
            fontSize: 20,
        },
        "& .MuiSvgIcon-root": {
            width: "2em",
            height: "2em"
        }
    },
    upperElements: {
        display: "flex",
        justifyContent: "start",
        alignItems: "center",
        marginBottom: "1.6rem",
        "& #learnerNum": {
            marginRight: "2.4rem",
            padding: "0.8rem 3.2rem",
            borderRadius: " 0.8rem",
            backgroundColor: "#00468b",
            color: "#ffffff",
            whiteSpace: "nowrap",
            fontSize: common.FONT_SIZE.title,
            fontWeight: "bold"
        },
        "& #examName": {
            color: "#00468b",
            fontSize: "1.8rem",
            fontWeight: "bold"
        }
    },
    learnerListContainer: {
        position: "relative"
    },
    links: {
        //position: "absolute",
        top: "-24px"
    },
    recordingPlayer: {
        width: "600px",
        height: "400px",
        border: "solid 1px"
    },
    multilineColor: {
        background: 'white'
    },
    textField: {
        "& > div": {
            background: "white",
            borderRadius: "0.8rem"
        }
    },
    buttonStyle: {
        display: "flex",
        justifyContent: "start",
        marginTop: "1.2rem",
        marginBottom: "2.4rem",
        "& button": {
            marginRight: "1.8rem"
        },
        "& button:hover": {
            backgroundColor: "#003f71",
        },
        "& button:focus": {
            outline: "none !important",
        },
        "& .backBtn:hover": {
            backgroundColor: "#003f71",
            color: "white"
        }
    },
    remarkText: {
        "& .MuiFormControl-root": {
            borderRadius: "0.8rem"
        }
    }
});


export interface transitRecordingValue {
    examName: string,
    learnerNum: string
};

export interface transitPram {
    pathname: string,
    state: transitRecordingValue
};

const TAB_INDEX_PHOTO = 0;
const TAB_INDEX_ENV_RECORD = 1;
const TAB_INDEX_RECORD = 2;

export function RecordingPlayback() {

    // -----共通関数の宣言-----
    const {
        params,
        go, // 画面遷移 
        api  // API呼び出し
    } = common.useCommon();

    const classNames = useStyles();

    // -----定数の定義-----
    //遷移元から受け取る受験者データ
    const location: transitPram = useLocation();
    const transitValue: transitRecordingValue = location.state;


    // ----- states
    const [examStateName, setExamStateName] = React.useState("");
    const [examLearners, setExamLearners] = React.useState([] as Learner[]);
    const [checkCheats, setCheckCheats] = React.useState([] as CheckCheat[]);
    const [cheats, setCheats] = React.useState([] as Cheat[]);
    const [state, setState] = React.useState({
        learnerIndex: 0,
        tabIndex: TAB_INDEX_PHOTO,
        src: "" as string | undefined
    });
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [cheatCheckState, setCheatCheckState] = React.useState({
        cheatId: 0,
        adminUserId: "",
        noteText: "",
    });

    // -----API-----
    // 受験者データを取得する
    function getLearnerData(args?: any): Promise<APIResponse<Learner[]>> {
        return api("/api/o-learner", "GET", args)
    }

    function getExamData(args?: any): Promise<APIResponse<Exam[]>> {
        return api("/api/o-exam", "GET", args)
    }

    async function getMovieUrl(userName: string, isEnv = false) {
        var res: APIResponse<{ sas: string; expired: string }> =
            await api("/api/o-storage", "GET", {
                userName,
                isEnv
            });
        return res.value;
    }

    function goLearner(learnerIndex: number, learners: Learner[], tabIndex: number) {
        try {
            const l = learners[learnerIndex];
            go('/recording-playback/' + l.executionId + '/' + l.userName + '/' + tabIndex);
            return true;
        } catch (err) {
            return false;
        }
    }

    async function getCheats() {
        var res: APIResponse<Cheat[]> =
            await api("/api/o-cheat", "GET");
        return res;
    }

    async function getCheckCheats(executionId: number, userName: string) {
        var res: APIResponse<CheckCheat[]> =
            await api("/api/o-learnerCheck", "GET", { executionId, userName });
        return res.value;
    }

    async function postCheckCheats(cheat: CheckCheat) {
        var res: APIResponse<CheckCheat> =
            await api("/api/o-learnerCheck", "POST", cheat);
        return res.value;
    }

    function setTabData(
        tabIndex: number,
        learnerIndex: number) {
        // examLearnersの最新の値を利用できるように
        // コールバックで全体を囲む 
        setExamLearners(_examLearners => {
            // indexの範囲チェック
            if (learnerIndex < 0
                || _examLearners.length <= learnerIndex) {
                return _examLearners;
            }
            // 指定された受験者
            const _learner = _examLearners[learnerIndex];
            console.log(_learner);
            getCheckCheats(_learner.executionId, _learner.userName)
                .then((v) => {
                    setCheckCheats(x => v ?? []);
                });
            switch (tabIndex) {
                case 0:
                    // 本人
                    setState((s) => {
                        return {
                            learnerIndex,
                            tabIndex,
                            src: _learner.portrait
                        };
                    });
                    break;
                case 1:
                    // 会場
                    // アクセス権付きの一時URLをユーザー名を引数に取得
                    getMovieUrl(_learner.userName, true)
                        .then((sasData) => {
                            setState((s) => {
                                return {
                                    learnerIndex,
                                    tabIndex,
                                    src: sasData == null ? "" : sasData.sas
                                };
                            });
                        });
                    break;
                case 2:
                    // 受験
                    // アクセス権付きの一時URLをユーザー名を引数に取得
                    getMovieUrl(_learner.userName)
                        .then((sasData) => {
                            setState((s) => {
                                return {
                                    learnerIndex,
                                    tabIndex,
                                    src: sasData == null ? "" : sasData.sas
                                };
                            });
                        });
                    break;
            }

            return _examLearners;
        });
    }

    // 初期化関数
    async function init() {
        // サーバーから認証してデータ取得
        try {
            const resLearners = await getLearnerData({ executionId: params.examId });
            if (resLearners.errorCode !== 20000) {
                common.alertError(resLearners.errorTitle, resLearners.errorDetail);
                return;
            }
            // 受験番号ソート
            resLearners.value.sort(function (a: Learner, b: Learner) {
              return a.learnerNumber.localeCompare(b.learnerNumber);
            });
            setExamLearners(s => resLearners.value);
            const index = resLearners.value.findIndex((x) => {
                return x.userName == params.learnerId;
            });
            setTabData(Number(params.tabIndex), index);
        } catch (err) {
            alert(common.ResponseMessages.Error_GetLearner);
        }
        // 不正マスタを取得
        try {
            const resCheats = await getCheats();
            if (resCheats.errorCode !== 20000) {
                common.alertError(resCheats.errorTitle, resCheats.errorDetail);
                return;
            }
            if (resCheats.value !== null) {
                setCheats(resCheats.value);
            }
        } catch (err) {
            alert(common.ResponseMessages.Error_GetExam);
        }
        // 試験名を取得
        try {
            if (transitValue !== undefined) {
                setExamStateName(transitValue.examName);
                return;
            }
            const resExam = await getExamData({ id: params.examId });
            if (resExam.errorCode !== 20000) {
                common.alertError(resExam.errorTitle, resExam.errorDetail);
                return;
            }
            if (resExam.value !== null) {
                setExamStateName(resExam.value[0].name);
            }
        } catch (err) {
            alert(common.ResponseMessages.Error_GetExam);
        }
    }

    React.useEffect(() => {
        init();
    }, [params.examId, params.learnerId, params.tabIndex]);

    // 現在のタブで対象の不正疑惑項目
    const activeCheats = cheats.filter((x) => {
        return state.tabIndex == TAB_INDEX_PHOTO && x.extentionCode == "self_photo"
            || state.tabIndex == TAB_INDEX_ENV_RECORD && x.extentionCode == "env_record"
            || state.tabIndex == TAB_INDEX_RECORD && x.extentionCode == "record"
    });

    let activeCheckCheats = [] as CheckCheat[];
    try {
        const learner = examLearners[state.learnerIndex];
        // 現在の不正疑惑ログで現在のタブの対象
        activeCheckCheats = checkCheats.filter(x => {
            if (x.userName != learner.userName) {
                return false;
            }
            const cheat = activeCheats.find(y => y.id == x.cheatId);
            return !!cheat;
        });
    } catch (e) {

    }


    return (
        <Layout navLinks={normalLinks}>
            <div className={classNames.recordingPlaybackRoot}>
                <ButtonContainer className={classNames.buttonStyle}>
                    <DefaultBackButton className="backBtn" onClick={() => { go(`/learner/index/${params.examId}`) }}>
                        <ArrowBackIosIcon />
                        <span style={{ paddingBottom: "0.2rem" }}>&nbsp;戻る</span>
                    </DefaultBackButton>
                </ButtonContainer>
                <AppBar position="static">
                    <Tabs value={state.tabIndex} onChange={(event, newValue) => {
                        //setTabData(newValue, state.learnerIndex);
                        goLearner(state.learnerIndex, examLearners, newValue);
                    }}>
                        <Tab label="本人" />
                        <Tab label="会場" />
                        <Tab label="受験" />
                    </Tabs>
                </AppBar>
                <Grid container spacing={3}>
                    <Grid item xs>
                        <Paper variant="outlined" square onDoubleClick={(e) => {
                            if (!state.src) {
                                return;
                            }
                            try {
                                const video = document.querySelector(`video.${classNames.recordingPlayer}`) as HTMLVideoElement;
                                setIsModalOpen(true);
                                video.pause();
                            }
                            catch (err) {
                                return;
                            }

                        }}>
                            <div hidden={state.tabIndex != TAB_INDEX_PHOTO}>
                                <div
                                    className={classNames.recordingPlayer}>
                                    <img
                                        hidden={!state.src}
                                        style={{
                                            objectFit: "scale-down",
                                            width: "100%",
                                            height: "100%"
                                        }}
                                        src={state.src}
                                    />
                                    <h2 hidden={!!state.src}>
                                        まだ本人写真が保存されていません。
                                    </h2>
                                </div>
                            </div>
                            <div hidden={state.tabIndex == TAB_INDEX_PHOTO}>
                                <video
                                    playsInline
                                    controls
                                    className={classNames.recordingPlayer}
                                    controlsList="nodownload nofullscreen noremoteplayback"
                                    src={state.src}
                                    hidden={!state.src}
                                ></video>
                                <h2
                                    className={classNames.recordingPlayer}
                                    hidden={!!state.src}>
                                    まだ動画が保存されていません。
                                </h2>
                            </div>
                            <h2 id="learnerNum">
                                受験番号：{
                                    function () {
                                        try {
                                            return examLearners[state.learnerIndex]
                                                .learnerNumber;
                                        }
                                        catch (err) {
                                            return ""
                                        }
                                    }()
                                }
                            </h2>
                        </Paper>
                    </Grid>
                    <Grid item xs>
                        <Paper variant="outlined" square style={{
                            height: 400,
                            overflow: "scroll"
                        }}>
                            {
                                activeCheckCheats.map((check) => {
                                    const c = cheats.find((x) => x.id == check.cheatId);
                                    if (!check || !c) {
                                        return <div />;
                                    }
                                    return (
                                        <div>
                                            <hr />
                                            <p>
                                                不正報告：{c.name}
                                            </p>
                                            <p>
                                                報告者：{check.adminUserName}
                                            </p>
                                            <p hidden={state.tabIndex == TAB_INDEX_PHOTO}>
                                                動画時刻（ms）：{check.cheatDatetime}
                                            </p>
                                            <p>
                                                備考：{check.noteText}
                                            </p>
                                        </div>
                                    );
                                })
                            }
                        </Paper>
                    </Grid>
                    <Grid item xs>

                    </Grid>
                </Grid>
                <CustomModal
                    openModal={isModalOpen}
                    setOpenModal={() => {
                        setCheatCheckState({
                            cheatId: 0,
                            adminUserId: "",
                            noteText: "",
                        })
                        setIsModalOpen(false);
                    }}
                    modalFunc={async () => {
                        if (cheatCheckState.cheatId === 0) return;
                        let learner: Learner;
                        let time: number;
                        try {
                            learner = examLearners[state.learnerIndex];
                            const video = document.querySelector(`video.${classNames.recordingPlayer}`) as HTMLVideoElement;
                            time = video.currentTime;
                        }
                        catch (err) {
                            return;
                        }
                        const c = {
                            executionId: learner.executionId,
                            userName: learner.userName,

                            cheatDatetime: Math.floor(time),
                            cheatId: cheatCheckState.cheatId,
                            adminUserId: cheatCheckState.adminUserId,
                            noteText: cheatCheckState.noteText,

                            // dummy
                            id: 0,
                            modified: new Date().toISOString(),
                            deleteFlag: false
                        };
                        const resValue = await postCheckCheats(c);
                        setCheckCheats(x => {
                            if (resValue) {
                                return [...x, resValue];
                            } else {
                                return x;
                            }
                        })
                    }}
                >
                    <Grid container spacing={3}>
                        <Grid item xs>
                            <div>
                                報告
                            </div>
                            <div>
                                <FormControl component="fieldset">
                                    <RadioGroup onChange={(e, value) => {
                                        setCheatCheckState(x => {
                                            return {
                                                ...x,
                                                cheatId: parseInt(value)
                                            };
                                        });
                                    }}>
                                        {
                                            activeCheats.map((x, index) => {
                                                return (
                                                    <FormControlLabel
                                                        key={index}
                                                        value={x.id.toString()}
                                                        control={<Radio />}
                                                        label={x.name} />
                                                );
                                            })
                                        }
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        </Grid>
                        <Grid item xs>
                            <div>
                                備考
                            </div>
                            <div>
                                <TextField
                                    multiline
                                    rowsMax={6}
                                    rows={6}
                                    onChange={(e) => {
                                        const noteText = e.target.value;
                                        setCheatCheckState(x => {
                                            return {
                                                ...x,
                                                noteText
                                            };
                                        });
                                    }}
                                />
                            </div>
                        </Grid>
                    </Grid>
                </CustomModal>
                <BottomNavigation
                    className={classNames.bottomNavigation}
                    onChange={(event, actionValue) => {
                        // 押されたボタンによって次の受験者の配列Indexを求める
                        let newIndex = state.learnerIndex;
                        switch (actionValue) {
                            case "first":
                                newIndex = 0;
                                break;
                            case "prev":
                                newIndex = state.learnerIndex - 1;
                                break;
                            case "next":
                                newIndex = state.learnerIndex + 1;
                                break;
                            case "last":
                                newIndex = examLearners.length - 1;
                                break;
                            default:
                                break;
                        }
                        goLearner(newIndex, examLearners, Number(params.tabIndex));
                    }}
                    showLabels
                >
                    <BottomNavigationAction
                        disabled={state.learnerIndex <= 0}
                        icon={<FirstPageIcon />}
                        value="first" label="先頭" />
                    <BottomNavigationAction
                        disabled={state.learnerIndex <= 0}
                        icon={<NavigateBeforeIcon />}
                        value="prev" label="前頁" />
                    <BottomNavigationAction
                        disabled
                        label={
                            "" + (state.learnerIndex + 1)
                            + "/" + examLearners.length} />
                    <BottomNavigationAction
                        disabled={state.learnerIndex >= (examLearners.length - 1)}
                        icon={<NavigateNextIcon />}
                        value="next" label="後頁" />
                    <BottomNavigationAction
                        disabled={state.learnerIndex >= (examLearners.length - 1)}
                        icon={<LastPageIcon />}
                        value="last" label="末尾" />
                </BottomNavigation>
                <ButtonContainer className={classNames.buttonStyle}>
                    <DefaultBackButton className="backBtn" onClick={() => { go(`/learner/index/${params.examId}`) }}>
                        <ArrowBackIosIcon />
                        <span style={{ paddingBottom: "0.2rem" }}>&nbsp;戻る</span>
                    </DefaultBackButton>
                </ButtonContainer>
            </div>
        </Layout >
    );
}

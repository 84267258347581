import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import * as common from '../../../common';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { DefaultButton, ButtonContainer, DefaultBackButton } from '../../StylesUI/CommonLayouts';
import { Choice, Extension, Question } from '../../../react-app-env';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { Box } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles((theme: Theme) =>
({
    mobileNav: {
        position: "sticky",
        top: 0,
        height: "10%",
        background: "linear-gradient(to right, rgba(0, 70, 139, 1), rgba(67, 120, 182, 1))",
    },
    mobileReceptionTitle: {
        paddingTop: "6px",
        paddingLeft: "6px",
        fontWeight: "bold",
        fontFamily: "Georgia, 'Times New Roman', serif",
        color: "white",
        height: "100%",
    },
    mobileLoginMenu: {
        height: "100%",
    },
    mobileNavBtn: {
        width: "100%",
        height: "100%",
        borderRadius: "unset",
        border: "solid 1px #003f71",
        outline: "none",
        "&:disabled": {
            backgroundColor: "#003f71",
            color: "#ffffff",
        },
    },
    headerBox: {
        background: "radial-gradient(ellipse at center bottom, rgba(0,137,190,1) 0%,rgba(5,49,113,1) 100%)",
        padding: "6px",
    },
    remainingTimeBox: {
        display: "inline-block",
        verticalAlign: "middle",
        padding: "1px 10px 1px",
        backgroundColor: "#b7cde6",
        boxShadow: "4px 4px 8px rgb(0 0 0 / 15%) inset",
        fontSize: "8px",
        color: "#003f71",
        borderRadius: "10px",
    },
    remainingTime: {
        display: "inline-block",
        fontSize: "11px",
        fontWeight: "bold",
        color: "#003f71",
        textShadow: "1px 1px 2px #ffffff, 1px -1px 2px #ffffff, -1px 1px 2px #ffffff, -1px -1px 2px #ffffff"
    },
    recordingScreen: {
        width: "80%",
        fontSize: "12px",
        backgroundColor: "rgb(60 60 60)",
        color: "#ffffff",
        textAlign: "center",
        padding: "60px 10px",
        margin: "10px 0",
    },
    rowContainer: {
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        marginBottom: "10px",
        "& Button": {
            display: "inline-block",
            verticalAlign: "middle",
            backgroundColor: "#b7cde6",
            boxShadow: "4px 4px 8px rgb(0 0 0 / 15%) inset",
            color: "black",
            borderRadius: "4px",
            minWidth: "40px",
        },
        "& button:hover": {
            backgroundColor: "#003f71",
            color: "white",
        },
        "& #finishBtn, #leaveBtn": {
            marginTop: "10px",
            marginBottom: "10px",
            fontSize: "11px",
        },
    },
    buttonContainer: {
        "& Button": {
            padding: "0",
            backgroundColor: "#0c326f",
            color: "white",
            border: "solid 0.5px white",
            borderRadius: 0,
            margin: 0,
            minWidth: "40px",
        },
        "& button:hover": {
            backgroundColor: "#003f71",
        },
        "& button:disabled": {
            backgroundColor: "#0c326f",
        },
        "& .nextBtn": {
            flexBasis: "27%",
            borderRight: "none",
        },
        "& #openBtn": {
            flexBasis: "46%",
        },
        "& #backBtn": {
            flexBasis: "27%",
            borderLeft: "none",
        },
        "& .MuiSvgIcon-root": {
            fontSize: "11px",
        }
    },
    headerTextStyle: {
        fontSize: "11px",
        backgroundColor: "#b7cde6",
        fontWeight: "bold",
        color: "#003f71",
    },
    choicesListStyle: {
        paddingLeft: "35px",
        "& li": {
            marginBottom: "2px",
        },
        "& svg": {
            fontSize: "11px",
        }
    },
    choiceContainer: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        "& .choiceTextStyle": {
            paddingRight: "10px",
            margin: "auto 0",
            fontSize: "11px",
            lineHeight: "12px",
            width: "100%",
            whiteSpace: "pre-wrap",
            wordBreak: "break-all",
            color: "black",
            border: "none",
            resize: "none",
            outline: "none"
        },
    },
    endButtonContainer: {
        display: "flex",
        justifyContent: "flex-end",
        "& Button": {
            backgroundColor: "#0c326f",
            borderRadius: "4px",
            minWidth: "40px",
        },
        "& button:hover": {
            backgroundColor: "#003f71",
        },
    },
})
);

export function MobileDisplay(props: { endViewFlag: boolean, remainingTime: number, extensions: Extension[], selectionChoices: number[], handleSelectionChange: any, choices: Choice[], answerText: string, handleTextChange: any, question: Question, endButtonText: string, headerText: string, onClickBackBtn: any, onClickNextBtn: any, onClickFinishBtn: any }) {

    const classNames = useStyles();

    const parentContainer = { maxHeight: "100%", overflow: "auto" };

    // 表示する残り時間
    const dispRemainingTime = (time: number) => {
        var sec = time % 60 < 10 ? "0" + time % 60 : time % 60;
        var min = Math.floor(time / 60) % 60 < 10 ? "0" + Math.floor(time / 60) % 60 : Math.floor(time / 60) % 60;
        var hour = Math.floor(time / 3600);
        return hour.toString() + ":" + min.toString() + ":" + sec.toString();
    }

    // 選択肢のナンバリング種別の切り替え
    const switchNumberingType = () => {
        let type = ""
        switch (props.question.choiceNumberingType) {

            case 0: type = "decimal"; break;

            case 1: type = "katakana"; break;

            case 2: type = "lower-roman"; break;

            case 3: type = "upper-roman"; break;

            case 4: type = "none"; break;

        }
        return type;
    }

    return (
        <div style={parentContainer}>
            <Container style={{ padding: 0 }}>

                <div className={classNames.mobileNav}> {/* ヘッダー */}

                    <Grid container justify="space-between" alignItems="center">
                        <Grid item className={classNames.mobileReceptionTitle}>
                            <div>
                                <p>
                                    {common.APP_DISP_NAME}
                                </p>
                            </div>
                        </Grid>
                        <Grid item className={classNames.mobileLoginMenu}>
                            <IconButton className={classNames.mobileNavBtn} disabled>
                                <MenuIcon />
                            </IconButton>
                        </Grid>
                    </Grid>

                </div>

                <div style={{ display: "flex" }} > {/* メイン */}

                    <Grid container justify="center" >

                        <Grid item xs={12} className={classNames.headerBox} >

                            <Grid container justify="space-between">

                                <Grid item xs={7} >
                                    <span className={classNames.remainingTimeBox} >
                                        残り時間：<span className={classNames.remainingTime} >{dispRemainingTime(props.remainingTime)}</span>
                                    </span>

                                    <div className={classNames.recordingScreen} hidden={(props.extensions.find(x => x.code === 'record')?.parameter ?? 0) < 1}>
                                        録画
                                    </div>
                                </Grid>

                                <Grid item xs={5}>
                                    <ButtonContainer className={classNames.rowContainer} >
                                        <DefaultButton id="finishBtn" onClick={props.onClickFinishBtn}>
                                            {/* { props.endViewFlag ? "解答終了確認へ" : "解答を終了する" }*/}
                                            {props.endViewFlag ? "解答終了確認へ" : "試験を終了する"}
                                        </DefaultButton>
                                        {/*
                                            <DefaultButton id="leaveBtn" disabled hidden={(props.extensions.find(x => x.code === 'leave')?.parameter ?? 0) < 1}>
                                                {"途中退席する"}
                                            </DefaultButton>
                                        */}
                                    </ButtonContainer>
                                </Grid>

                            </Grid>

                        </Grid>

                        <Grid item xs={12} >
                            <ButtonContainer className={classNames.buttonContainer}>

                                {props.question.editOrder === 1
                                    ? <DefaultBackButton id="backBtn" disabled></DefaultBackButton>
                                    : <DefaultBackButton id="backBtn" onClick={props.onClickBackBtn} style={{ fontSize: "8px"}}><ArrowBackIosIcon /><span>&nbsp;前の問題へ</span></DefaultBackButton>}

                                <DefaultButton id="openBtn" disabled></DefaultButton>

                                {props.endButtonText !== "次の問題へ"
                                    ? <DefaultButton className="nextBtn" disabled></DefaultButton>
                                    : <DefaultButton className="nextBtn" onClick={props.onClickNextBtn} style={{ fontSize: "8px" }}><span>次の問題へ&nbsp;</span><ArrowForwardIosIcon /></DefaultButton>}

                            </ButtonContainer>

                            <Box marginBottom={1} className={classNames.headerTextStyle}>
                                <span style={props.question.sectionFlag ? { border: "solid 1px", marginLeft: "5px", padding: "0px 4px", width: "fit-content" } : { marginLeft: "5px" }}>{props.headerText}</span>
                            </Box>

                            <div style={{ backgroundColor: "#ffffff" }}>
                                <Box marginBottom={4}>
                                    <ul className={classNames.choicesListStyle} style={{ listStyleType: switchNumberingType(), fontSize: "12px"}}>
                                        {props.question?.sectionFlag === false ?
                                            props.question.answerType > 1 ?
                                                props.question.answerType === 2 ?
                                                    <input type="text" pattern="[0-9]+" inputMode="numeric" value={props.answerText ?? ""}
                                                        onChange={(event) => props.handleTextChange(props.question.id, event.target.value, props.question.editOrder)} />
                                                    :
                                                    <input type="text" value={props.answerText ?? ""}
                                                        onChange={(event) => props.handleTextChange(props.question.id, event.target.value, props.question.editOrder)} />
                                                :
                                                props.choices.map((choice: Choice) => (
                                                    <li key={choice.id}>
                                                        <div className={classNames.choiceContainer}>
                                                            <Box key={choice.id} style={{ width: "fit-content" }}>
                                                                {
                                                                    props.question.answerType === 0 ?

                                                                        <Radio
                                                                            value={choice.id}
                                                                            checked={props.selectionChoices.filter(s => s === choice.id).length > 0}
                                                                            onClick={() => { props.handleSelectionChange(choice.id, props.question.editOrder) }} 
                                                                        />
                                                                        :
                                                                        <Checkbox
                                                                            value={choice.id}
                                                                            checked={props.selectionChoices.filter(s => s === choice.id).length > 0}
                                                                            onClick={() => { props.handleSelectionChange(choice.id, props.question.editOrder) }} 
                                                                        />

                                                                }
                                                            </Box>

                                                            <div className="choiceTextStyle" >{choice.bodyText}</div>
                                                        </div>
                                                    </li>
                                                )) : <div></div>
                                        }
                                    </ul>
                                    <ButtonContainer className={classNames.endButtonContainer} >
                                        <DefaultButton hidden={props.endButtonText === "次の問題へ"} style={{ fontSize: "8px", marginRight: "10px" }} onClick={props.onClickFinishBtn}>
                                            {/* {props.endViewFlag ? "解答終了確認へ" : "解答を終了する"}*/}
                                            {props.endViewFlag ? "解答終了確認へ" : "試験を終了する"}
                                        </DefaultButton>
                                    </ButtonContainer>
                                </Box>
                            </div>
                        </Grid>

                    </Grid>
                </div>

            </Container>
        </div>
    );
}

import React from 'react';

import { createUseStyles } from 'react-jss';
import TextField from '@material-ui/core/TextField';
import { useLocation } from "react-router-dom";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';


import { Layout } from '../Layout';
import * as common from "../../common";
import { transitExamValue,initialExamStateValue} from '../Exam/Index';
import {normalLinks,goExamRegister} from '../NavLinks/NavLinks';
import { Exam ,APIResponse} from '../../react-app-env';
import {DefaultButton,ButtonContainer,DefaultBackButton}  from '../StylesUI/CommonLayouts';


// 引数で生成するCSSを定義してuseStyles関数を生成
const useStyles = createUseStyles({
    examRegisterRoot: {
        fontSize: common.FONT_SIZE.mainText,
        minWidth:"650px",
        "& .formStyle-1-2-4": {
            height:"auto"
        },
        "& .MuiFormHelperText-root ": {
            width: "300px"
        },
        "& h4": {
            color: "#003f71",
            fontWeight: "bold",
            marginBottom:"30px",
            fontSize:common.FONT_SIZE.title
        },
        "& #acceptPeriodForm  p ,#executePeriodForm p":{
            width: "200px",
            lineHeight: 1
        }
    },
    linkStyle: {
        opacity: 0.9,
        backgroundColor:"#4378b6", 
        padding:"6px 16px",
        borderRadius: "4px",
        color:"white",
        marginRight:"5px"
    },
    buttonStyle: {
        justifyContent:"space-between",
        width:"450px",
        "& button":{
            fontSize:common.FONT_SIZE.button,
            marginTop:"50px"
        },
        "& button:hover": {
            backgroundColor:"#003f71", 
        },
        "& button:focus": {
            outline:"none !important", 
        },
        "& .backBtn:hover": {
            backgroundColor:"#003f71",
            color:"white" 
        }
    },
    formStyle: {
        display: "flex",
        justifyContent: "flex-start",
        flexDirection: "column",
        height:"8rem",
        marginBottom:"2rem",
        "& input ,textarea ,.MuiFormHelperText-root ,.MuiInputBase-root":{
            fontSize:common.FONT_SIZE.mainText,
        },
        "& .MuiTypography-root":{
            paddingTop:"2px",
            fontSize:common.FONT_SIZE.mainText,
        },
        "& svg":{
            fontSize:28
        },
    },
    labelStyle: {
        paddingRight: "60px"
    },
    inputFormContainer : {
        display: "flex",
        justifyContent: "space-between",
        
    }
});



export function ExamRegister() {
    // -----定数の定義-----
    //遷移元から受け取る試験データ
    const location:transitExamValue=useLocation();
    const examValue:Exam=location.state;


    // -----共通関数の宣言-----
    const {
        params,
        api,
        go // 画面遷移 
    } = common.useCommon();

    

    // -----API-----
    // 試験データを取得する
    function getExamData(args?: any):Promise<APIResponse<Exam[]>> {
        return api("/api/o-exam", "GET", args)
    }


    // -----Handler-----
    //登録ボタンクリック時の処理
    const onClickRegister=(url:string)=>{
        //初めてクリックされたら各エラー表示をONにする
        if(!isClicked){
            setAllIsError();
            setisClicked(true);
        } 

        if(validateAllForms()) go(getTransitionInfo(url));
      
    }

    //フォーム入力時の処理
    const handleInput=(event: React.ChangeEvent<HTMLInputElement>)=>{

        const name = event.target.name as keyof typeof examStateValue;

        setExamStateValue({
            ...examStateValue,
            [name]:event.target.value
        })

        switch(name){
            case "name":if(validateExamName(event.target.value)) break; 
                            else if(!isError.name){
                                setIsError({
                                            ...isError,
                                            [name]:true
                                        }); break;
                            }; break;

            case "acceptStart":validateAcceptAndExecute(name,event.target.value); 
                             if(!isError.acceptStart){
                                setIsError({
                                            ...isError,
                                            [name]:true
                                        });
                                        break;
                            }; break;

            case "acceptEnd":validateAcceptAndExecute(name,event.target.value); 
                            if(!isError.acceptEnd){
                                setIsError({
                                            ...isError,
                                            [name]:true
                                        }); 
                                        break;
                            }; break;

            case "executeStart":validateAcceptAndExecute(name,event.target.value);
                            if(!isError.executeStart){
                                setIsError({
                                            ...isError,
                                            [name]:true
                                        }); 
                                        break;
                            }; break;

            case "executeEnd":validateAcceptAndExecute(name,event.target.value);
                            if(!isError.executeEnd){
                                setIsError({
                                            ...isError,
                                            [name]:true
                                        });         
                                        break;
                            };  break;

            case "executeTime":if(validateExecuteTime(event.target.value)) break; 
                            else if(!isError.executeTime){
                                setIsError({
                                            ...isError,
                                            [name]:true
                                        }); break;
                            };  
                            if(event.target.value === "") break;
                            break;

            case "learnerMax":if(validateLearnerMax(event.target.value)) break; 
                            else if(!isError.learnerMax){
                                setIsError({
                                            ...isError,
                                            [name]:true
                                        }); 
                            }
                            if(event.target.value === "") break;
                            break;
        }
    }
   
    //各項目でフォーカスアウトした時にエラー表示をONにする
    const handleBlur=(event: React.FocusEvent<HTMLInputElement>)=>{
        const name = event.target.name as keyof typeof examStateValue;

        setIsError({
            ...isError,
            [name]:true
        })
    }

    
    //各入力項目のバリデーション
    const validateExamName=(examName:string)=>{
        if(examName!==""){
            setExamNameErrorMessage("");
            return true;
        }else{
            setExamNameErrorMessage("入力して下さい");
            return false;
        }
    }


    const validateAcceptStart=(acceptStart:string,acceptEnd:string)=>{
        if(acceptStart===""){
            setAcceptStartErrorMessage("入力して下さい");
            return false;
        }
        // 2-107対応
        //else if (!validateLaterTime(acceptStart)) {
        //    setAcceptStartErrorMessage("現在より先の日時を入力して下さい");
        //    return false;
        //}
        else if(validateTime(acceptStart,acceptEnd)){
            setAcceptStartErrorMessage("");
            return true;
        }else if(acceptEnd!==""){
            setAcceptStartErrorMessage("正しい受付期間を入力して下さい");
            return false;
        }
        setAcceptStartErrorMessage("");
        return false;
    }


    const validateAcceptEnd=(acceptEnd:string,acceptStart:string,executeStart:string)=>{
        if(acceptEnd===""){
            setAcceptEndErrorMessage("入力して下さい");
            return false;
        }
        // 2-107対応
        //else if(!validateLaterTime(acceptEnd)){
        //    setAcceptEndErrorMessage("現在より先の日時を入力して下さい");
        //    return false;
        //}
        else if(!validateTime_AcceptAndExecute(acceptEnd,executeStart)&&isError.executeStart){
            setAcceptEndErrorMessage("受付期間は実施期間よりも前の日時に設定して下さい");
            return false;
        }
        else if(validateTime(acceptStart,acceptEnd)){
            setAcceptEndErrorMessage("");
            return true;
        }else if(acceptStart!==""){
            setAcceptEndErrorMessage("正しい受付期間を入力して下さい");
            return false;
        }
        setAcceptEndErrorMessage("");
        return false;
    }

    const validateExecuteStart=(executeStart:string,executeEnd:string,acceptEnd:string)=>{
        if(executeStart===""){
            setExecuteStartErrorMessage("入力して下さい");
            return false;
        // 2-107対応
        //}else if(!validateLaterTime(executeStart)){
        //    setExecuteStartErrorMessage("現在より先の日時を入力して下さい");
        //    return false;
        }else if(!validateTime_AcceptAndExecute(acceptEnd,executeStart)&&isError.acceptEnd){
            setExecuteStartErrorMessage("実施期間は受付期間よりも先の日時に設定して下さい");
            return false
        }
        else if(validateTime(executeStart,executeEnd)){
            setExecuteStartErrorMessage("");
            return true;
        }else if(executeEnd!==""){
            setExecuteStartErrorMessage("正しい実施期間を入力して下さい");
            return false;
        }
        setExecuteStartErrorMessage("");
        return false
    }


    const validateExecuteEnd=(executeEnd:string,executeStart:string)=>{

        if(executeEnd===""){
            setExecuteEndErrorMessage("入力して下さい");
            return false;
        // 2-107対応
        //}else if(!validateLaterTime(executeEnd)){
        //    setExecuteEndErrorMessage("現在より先の日時を入力して下さい");
        //    return false;
        }
        else if(validateTime(executeStart,executeEnd)){
            setExecuteEndErrorMessage("");
            return true;
        }else if(executeStart!==""){
            setExecuteEndErrorMessage("正しい実施期間を入力して下さい");
            return false;
        }
        setExecuteEndErrorMessage("");
        return false
    }


    const validateExecuteTime=(executeTime:string)=>{
        if(executeTime.match(/^([1-9]\d*|0)$/)&&executeTime.match(/[^0]/)){
            setExecuteTimeErrorMessage(""); 
            return true;
        }else{
            if(executeTime===""){
                setExecuteTimeErrorMessage("入力して下さい"); 
            }else if(executeTime.match(/^[0]/)){
                setExecuteTimeErrorMessage("1以上の整数を入力して下さい");
            }else{
                setExecuteTimeErrorMessage("半角数字で整数を入力して下さい");
            }
            return false;
        }
    }


    const validateAcceptAndExecute=(name:string,value:string)=>{
        let flag=true

        if(isError.acceptStart||name==="acceptStart"){
            if(!validateAcceptStart(name==="acceptStart" ? value : examStateValue.acceptStart,
            name==="acceptEnd" ? value : examStateValue.acceptEnd)) flag=false;
        }      

        if(isError.acceptEnd||name==="acceptEnd"){
             if(!validateAcceptEnd(name==="acceptEnd" ? value : examStateValue.acceptEnd,
             name==="acceptStart" ? value : examStateValue.acceptStart,
             name==="executeStart" ? value : examStateValue.executeStart)) flag=false;
        }

        if(isError.executeStart||name==="executeStart"){
             if(!validateExecuteStart(name==="executeStart" ? value : examStateValue.executeStart,
             name==="executeEnd" ? value : examStateValue.executeEnd,
             name==="acceptEnd" ? value : examStateValue.acceptEnd)) flag=false;
        }

        if(isError.executeEnd||name==="executeEnd"){
             if(!validateExecuteEnd(name==="executeEnd" ? value : examStateValue.executeEnd,
             name==="executeStart" ? value : examStateValue.executeStart)) flag=false;
        }

        return flag;
    }



    const validateLearnerMax=(learnerMax:string)=>{
        if(learnerMax.match(/^([1-9]\d*|0)$/)&&learnerMax.match(/[^0]/)){
            setLearnerMaxErrorMessage(""); 
            return true;
        }else{
            if(learnerMax===""){
                setLearnerMaxErrorMessage("入力して下さい"); 
            }else if(learnerMax.match(/^[0]/)){
                setLearnerMaxErrorMessage("1以上の整数を入力して下さい");
            }else{
                setLearnerMaxErrorMessage("半角数字で整数を入力して下さい");
            }
            return false;
        }
    }



    //2つの日時の検証
    const validateTime=(start:string,end:string)=>{
        const d1 =new Date(start);
        const d2 =new Date(end);
        const timeDifference=d2.getTime() - d1.getTime();
        if(timeDifference > 0)  return true 
        return false
    }

    const validateTime_AcceptAndExecute=(start:string,end:string)=>{
        const d1 =new Date(start);
        const d2 =new Date(end);
        const timeDifference=d2.getTime() - d1.getTime();
        if(timeDifference >= 0)  return true; 
        return false;
    }

    // 2-107対応
    //日時が現在時刻より先かの検証
    /*const validateLaterTime=(time:string)=>{
        const d1 =new Date();
        const d2 =new Date(time);
        const timeDifference=d2.getTime() - d1.getTime();
        if(timeDifference>0)  return true 
        return false
    }*/

    



    // -----汎用関数定義-----
   

    const validateAllForms=()=>{
        let flag = true;

        if(!validateExamName(examStateValue.name)) flag=false;

        if(!validateAcceptStart(examStateValue.acceptStart,examStateValue.acceptEnd)) flag=false;

        if(!validateAcceptEnd(examStateValue.acceptEnd,examStateValue.acceptStart,examStateValue.executeStart)) flag=false;

        if(!validateExecuteStart(examStateValue.executeStart,examStateValue.executeEnd,examStateValue.acceptEnd)) flag=false;

        if(!validateExecuteEnd(examStateValue.executeEnd,examStateValue.executeStart)) flag=false;

        if(!validateExecuteTime(String(examStateValue.executeTime))&&examStateValue.termType==1) flag=false;

        if(!validateLearnerMax(String(examStateValue.learnerMax))) flag=false;

        return flag
       
    }

    const setAllIsError=()=>{
        setIsError({
            name: true,
            acceptStart:true,
            acceptEnd:true,
            executeStart:true, 
            executeEnd:true,
            executeTime:true,
            learnerMax:true
        })
    }


    //試験時間の計算
    const calculateExecuteTime=()=>{
        const d1 =new Date(examStateValue.executeStart);
        const d2 =new Date(examStateValue.executeEnd);
        const executeTime=d2.getTime() - d1.getTime();
        const minutes=Math.floor(executeTime / (1000 * 60 ));
        if(isNaN(minutes)) return 0 
        return minutes
    }


    

    //確認ページへの遷移時のJSONデータ返す
    function getTransitionInfo(url:string):transitExamValue{
        const info={ 
            pathname: url,
            state : {   
                    id:Number(examStateValue.id),
                    name:examStateValue.name ,
                    status:Number(examStateValue.status),
                    termType:Number(examStateValue.termType),
                    shuffleFlag:examStateValue.shuffleFlag,
                    numberingType:examStateValue.numberingType,
                    acceptStart:examStateValue.acceptStart,
                    acceptEnd:examStateValue.acceptEnd,
                    executeStart:examStateValue.executeStart ,
                    executeEnd:examStateValue.executeEnd,
                    executeTime:examStateValue.termType==0 ? calculateExecuteTime() : Number(examStateValue.executeTime),
                    learnerMax:Number(examStateValue.learnerMax),
                    guidanceUrl:examStateValue.guidanceUrl,
                    startViewFlag:examStateValue.startViewFlag,
                    startBodyHtml:examStateValue.startBodyHtml,
                    endViewFlag:examStateValue.endViewFlag ,
                    endBodyHtml:examStateValue.endBodyHtml,
                    allPoints:examStateValue.allPoints,
                    passPoints:examStateValue.passPoints,
                    deleteFlag:examStateValue.deleteFlag,
                    modified:examStateValue.modified,
                    choiceDeviceFlag:examStateValue.choiceDeviceFlag,
                    afterReadFlag:examStateValue.afterReadFlag,
                    acceptExpire:examStateValue.acceptExpire,
                    advanceExplanationVideoUrl:examStateValue.advanceExplanationVideoUrl
            } 
    } ;
        return info;
    }


    const setExamDataById=(examId:string)=>{

        let examValue = initialExamStateValue

        getExamData({id:Number(examId)})
            .then((data:APIResponse<Exam[]>) => {

                if (data.errorCode !==20000) {
                    common.alertError(data.errorTitle , data.errorDetail);
                    return;
                }

                if(data.value !== null){

                    examValue = data.value[0]
                    setExamStateValue(examValue)

                }

            }).catch((err) => {
                alert(common.ResponseMessages.Error_GetExam);
            });
    }


    //実施期間、受付期間入力フォームの初期値設定用
    const getInitialDate=(numArgs:number)=>{
        const d1 = new Date();
        const date= d1.setDate(d1.getDate() + numArgs);
        const initialDate = common.dateFormat(date,"L").replace(/\//g,'-')+"T00:00:00";
        return initialDate
    }



    // -----スタイルの宣言-----
    const classNames = useStyles();


    


    // -----state-----
    const [state, setState] = React.useState({ type: "", url: "" });
    const [examStateValue,setExamStateValue]=React.useState<Exam>(initialExamStateValue);
    const [examNameErrorMessage,setExamNameErrorMessage]=React.useState("");
    const [acceptStartErrorMessage,setAcceptStartErrorMessage]=React.useState("");
    const [acceptEndErrorMessage,setAcceptEndErrorMessage]=React.useState("");
    const [executeStartErrorMessage,setExecuteStartErrorMessage]=React.useState("");
    const [executeEndErrorMessage,setExecuteEndErrorMessage]=React.useState("");
    const [executeTimeErrorMessage,setExecuteTimeErrorMessage]=React.useState("");
    const [learnerMaxErrorMessage,setLearnerMaxErrorMessage]=React.useState("");
    const [isError,setIsError]=React.useState({
        name: false,
        acceptStart:false,
        acceptEnd:false,
        executeStart:false, 
        executeEnd:false,
        executeTime:false,
        learnerMax:false
    } )
    //登録、変更ボタンが初めて押されたかどうか
    const [isClicked,setisClicked]=React.useState(false);


    // -----use effefct-----
    //遷移元によって登録、変更に分岐
    React.useEffect(() => {
        switch (params.type) {
            case "register": setState({ type: "登録",  url: "/exam/confirm/register" });

                            setExamNameErrorMessage("入力して下さい");

                            let exam = examStateValue;

                            if(examValue!==undefined){
                                setExamStateValue(examValue);
                            }else{
                                setExamStateValue({
                                     ...exam,
                                     ["acceptStart"]:getInitialDate(1) ,
                                     ["acceptEnd"]: getInitialDate(2) ,
                                     ["executeStart"]:getInitialDate(3) ,
                                     ["executeEnd"]:getInitialDate(4) 
                                });
                            }


                            break;
                             
            case "edit": setState({ type: "編集", url: "/exam/confirm/edit" }); 
                         //examValueがundefindならAPIたたいてデータ持ってきてsetExamStateValueする
                         if(examValue!==undefined) setExamStateValue(examValue);
                         else {
                             //APIたたいてデータ持ってきてsetExamStateValueする
                             setExamDataById(params.examId);
                         }
        }

    }, []);
    

    

    return (
        <Layout navLinks={normalLinks} buttons={
            [
              { text: goExamRegister.text, handler: () => { go({pathname:goExamRegister.pathname,state:goExamRegister.state })} }
            ]
          }>
            <div className={classNames.examRegisterRoot}>

                <h4>試験 {state.type}</h4>
                
                <table>
                    <tbody>
                        <tr className={classNames.formStyle}>
                            <th className={classNames.labelStyle}><label>試験名称</label></th>
                            <td><TextField fullWidth value={examStateValue.name} name="name" onChange={handleInput}  onBlur={handleBlur}
                                variant="standard" required error={isError.name ? examNameErrorMessage!="" : false } helperText={isError.name ? examNameErrorMessage:""}/></td>
                        </tr>

                        <tr className={classNames.formStyle}>
                            <th className={classNames.labelStyle}><label>試験種別</label></th>
                            <td><FormControl  component="fieldset">
                                <RadioGroup  row aria-label="position" name="termType" defaultValue={String(examStateValue.termType)} value={String(examStateValue.termType)} >
                                    <FormControlLabel  value="0" control={<Radio onChange={handleInput} color="primary" />} label="一斉" />
                                    <FormControlLabel  value="1" control={<Radio onChange={handleInput} color="primary" />} label="任意" />
                                </RadioGroup>
                                </FormControl></td>
                        </tr>

                        <tr className={classNames.formStyle} id="acceptPeriodForm">
                            <th className={classNames.labelStyle}><label>受付期間</label></th>

                            <td className={classNames.inputFormContainer}>
                                <div style={{width:"206px"}}><TextField
                                    type="datetime-local"
                                    value={examStateValue.acceptStart}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    helperText={isError.acceptStart ? acceptStartErrorMessage:""} onBlur={handleBlur}
                                    name="acceptStart" onChange={handleInput}  required error={isError.acceptStart ? acceptStartErrorMessage!="":false}
                                /></div>     
                                <div style={{margin:"0 10px"}}>～</div>     
                                <div style={{width:"206px"}}><TextField
                                    id=""
                                    type="datetime-local"
                                    value={examStateValue.acceptEnd}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    helperText={isError.acceptEnd ? acceptEndErrorMessage:""} onBlur={handleBlur}
                                    name="acceptEnd" onChange={handleInput}  required error={isError.acceptEnd ? acceptEndErrorMessage!="":false}
                                /></div>     
                            </td>
                        </tr>

                        <tr className={classNames.formStyle} id="executePeriodForm">
                            <th className={classNames.labelStyle}><label>実施期間</label></th>
                            <td className={classNames.inputFormContainer}>
                                <div style={{width:"206px"}}><TextField 
                                    id=""
                                    type="datetime-local"
                                    value={examStateValue.executeStart}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    helperText={isError.executeStart ? executeStartErrorMessage:""} onBlur={handleBlur} 
                                    name="executeStart" onChange={handleInput}  required error={isError.executeStart ? executeStartErrorMessage!="":false}
                                /></div>
                                <div style={{margin:"0 10px"}}>～</div>                        
                                <div style={{width:"206px"}}><TextField
                                    id=""
                                    type="datetime-local"
                                    value={examStateValue.executeEnd}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    helperText={isError.executeEnd ? executeEndErrorMessage:""} onBlur={handleBlur}
                                    name="executeEnd" onChange={handleInput}  required error={isError.executeEnd ? executeEndErrorMessage!="":false}
                                /></div>
                            </td>
                        </tr>

                        <tr className={classNames.formStyle}>
                            <th className={classNames.labelStyle}><label>試験時間</label></th>
                            <td><TextField hidden={examStateValue.termType==1} style={{pointerEvents :"none",width:"100px"}} fullWidth value={calculateExecuteTime()} name="executeTime" onChange={handleInput}  required InputProps={{
                                    readOnly: true,
                                }}/>
                                <TextField hidden={examStateValue.termType==0} style={{width:"100px"}} fullWidth onBlur={handleBlur} type="number" InputProps={{ inputProps: { min: 1 } }}
                                value={examStateValue.executeTime} name="executeTime" onChange={handleInput}  helperText={isError.executeTime ? executeTimeErrorMessage:""} required error={isError.executeTime ? executeTimeErrorMessage!="":false}
                                />
                                <label >分</label>
                            </td>
                        </tr>

                        <tr className={classNames.formStyle}>
                            <th className={classNames.labelStyle}><label>最大人数</label></th>
                            <td><TextField style={{width:"100px"}} fullWidth value={examStateValue.learnerMax} name="learnerMax" onChange={handleInput} onBlur={handleBlur} type="number" InputProps={{ inputProps: { min: 1 } }}
                                variant="standard" helperText={isError.learnerMax ? learnerMaxErrorMessage:""} required error={isError.learnerMax ? learnerMaxErrorMessage!="":false}/></td>
                        </tr>

                        <tr className={classNames.formStyle}>
                            <th style={{paddingRight: "35px"}}><label>試験要項URL-(任意)</label></th>
                            <td><TextField fullWidth value={examStateValue.guidanceUrl} name="guidanceUrl" onChange={handleInput} onBlur={handleBlur}
                                variant="standard" /></td>
                        </tr>

                        <tr className={classNames.formStyle}>
                            <th style={{paddingRight: "35px"}}><label>試験説明動画URL-(任意)</label></th>
                            <td><TextField fullWidth value={examStateValue.advanceExplanationVideoUrl} name="advanceExplanationVideoUrl" onChange={handleInput} onBlur={handleBlur}
                                variant="standard" /></td>
                        </tr>

                    </tbody>
                </table>

                <ButtonContainer className={classNames.buttonStyle} >
                    <DefaultBackButton className="backBtn" onClick={() => { go("/exam/index") }}>戻る</DefaultBackButton>
                    <DefaultButton  onClick={() => { onClickRegister(state.url) }}>入力内容を確認する</DefaultButton>
                </ButtonContainer>

            </div>
        </Layout>
    );
}

import React from 'react';
import * as common from "../../common";

import { Layout } from '../Layout';
import { createUseStyles } from 'react-jss';
import { normalLinks, goExamRegister } from '../NavLinks/NavLinks';
import { APIResponse, MarkCSV } from '../../react-app-env';
import { Exam } from '../../react-app-env';
import { DefaultButton, ButtonContainer, DefaultBackButton } from '../StylesUI/CommonLayouts';
import { initialExamStateValue } from '../Exam/Index';
import Encoding from 'encoding-japanese';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';


// 引数で生成するCSSを定義してuseStyles関数を生成
const useStyles = createUseStyles({
  // 第一階層はCSSクラス名（実際は後ろに重複防止の接尾辞がつく）
  // homeRoot => .homeRoot-1-2-3 など
  homeRoot: {
    fontSize: common.FONT_SIZE.mainText,
    "& h4": {
      color: "#003f71",
      fontWeight: "bold",
      marginBottom: "30px",
      fontSize: common.FONT_SIZE.title
    },
  },
  linkStyle: {
    opacity: 0.9,
    backgroundColor: "#4378b6",
    padding: "6px 16px",
    borderRadius: "4px",
    color: "white",
    marginRight: "5px"
  },
  buttonStyle: {
    justifyContent: "center",
    "& button": {
      fontSize: common.FONT_SIZE.button,
      marginRight: "18px"
    },
    "& button:hover": {
      backgroundColor: "#003f71",
    },
    "& button:focus": {
      outline: "none !important",
    },
    "& .backBtn:hover": {
      backgroundColor: "#003f71",
      color: "white"
    }
  },
  links: {
    "& :hover": {
      color: "white",
      opacity: 1,
      textDecoration: "none"
    },
    marginBottom: "20px"
  },
  labelStyle: {
    paddingRight: "60px"
  },
  mainContainer: {
    display: "flex",
    justifyContent: "center",
    margin: "50px auto",
  },
  centerItemStyle: {
    margin: "50px auto",
    textAlign: "center"
  },
  formItemStyle: {
    marginBottom: "2.5rem",
    justifyContent: "flex-start",
    "& .MuiInputBase-input": {
      width: "210px"
    },
    "& input ,.MuiInputBase-root, .MuiSelect-nativeInput, .MuiSelect-select.MuiSelect-select ,.MuiMenuItem-root": {
      fontSize: common.FONT_SIZE.mainText,
    }
  }
});

const initialMarkStateValue: MarkCSV = {
  learnerNumber: "初期値",
  questionIds: [],
  answers: []
};

// 試験内容コード
interface LevelTesttype {
  key: number,
  valueEn: string,
  valueJp: string,
  code: string
}

// 試験内容コード一覧 (codeは仮値)
const LevelTesttypeMaster: LevelTesttype[] = [{

    key: 0,
    valueEn: "2g",
    valueJp: "2級（学科）",
    code: "C"
  }, {
    key: 1,
    valueEn: "2j",
    valueJp: "2級（実技）",
    code: "D"
  }, {
    key: 2,
    valueEn: "3g",
    valueJp: "3級（学科）",
    code: "E"
  }, {
    key: 3,
    valueEn: "3j",
    valueJp: "3級（実技）",
    code: "F"

}];

// 場所コード一覧 (仮値) 場所コードが固定値の場合は不要
const LocationCodeMaster: string[] = ["rt", "111", "222", "333", "444"];

// ユーザーの入力
interface ExamUIData {
  ExamNo: string; // 試験回
  LevelTesttype: LevelTesttype; // 試験級と試験種別
  LocationCode: string; // 場所コード
  VenueCode: string; // 会場コード
}

const initialExamUIData: ExamUIData = {
  ExamNo: "",
  LevelTesttype: LevelTesttypeMaster[0],
  LocationCode: LocationCodeMaster[0], // 場所コードが固定値の場合はここで指定
  VenueCode: "999" // 会場コード (仮値)
}


export function TKCCExportAnswer() {

  // -----共通関数の宣言-----
  const {
    params,
    go, // 画面遷移 
    api  // API呼び出し
  } = common.useCommon();



  // -----API-----
  //試験データを取得する
  function getExamData(args?: any): Promise<APIResponse<Exam[]>> {
    return api("/api/o-exam", "GET", args)
  }

  //解答データを取得する
  function getAnswerData(args?: any): Promise<APIResponse<MarkCSV[]>> {
    return api("/api/o-tkccAnswerCSV", "GET", args)
  }


  // -----Handler-----
  //受付済みの受験者データリストをダウンロード
  const getAcceptedCSV = () => {
    
    // ヘッダcsv
    const hHeader: string[] = ["ファイル名", "試験内容", "枚数"];
    const hFileName: string = "header_k" + examUIState.LevelTesttype.valueEn + ".csv";
    const headerData: any = [{
      "ファイル名": "", // 空
      "試験内容": examUIState.LevelTesttype.code,
      "枚数": markStateValue.length.toString()
    }];
    getResultCSV(headerData, hHeader, hFileName);

    // 成績csv
    const mHeader: string[] = ["ファイル名", "試験内容", "生_年", "生_月", "生_日", "受験番号"];
    const mFileName: string = "mark_k" + examUIState.LevelTesttype.valueEn + ".csv";
    const markData: any = [];
    var count: number = 0;
    for (const m of markStateValue) {

      const unitData: { [key: string]: string } = {};
      unitData["ファイル名"] = examUIState.ExamNo + examUIState.LocationCode + "_"
                              + examUIState.LevelTesttype.valueEn + "_" + examUIState.VenueCode + "_"
                              + m.learnerNumber.toString() + ".csv";
      unitData["試験内容"] = examUIState.LevelTesttype.valueJp;
      unitData["生_年"] = ""; // 空
      unitData["生_月"] = ""; // 空
      unitData["生_日"] = ""; // 空
      unitData["受験番号"] = m.learnerNumber.toString();

      var i: number = 1;
      for (const a of m.answers) {
        unitData["no_" + i.toString()] = a.toString();
        if (count < 1) {
          // 初回のみヘッダー用の配列に追加する
          var l: number = mHeader.length;
          mHeader[l] = "no_" + i.toString();
        }
        i++;
      }

      markData[count] = unitData;
      count++;
    }
    getResultCSV(markData, mHeader, mFileName);

  }


  // -----汎用関数の宣言-----

  const getResultCSV = (sourceData: any, csvHeader: string[], fileName: string) => {

    common.toCsv(sourceData, {noHeader: false, cols: csvHeader})
      .then((result) => {
        saveSjisTextFile(result, fileName);
      }).catch((err) => {
        alert(err);
      });
  };

  // Shift-JISでファイルを保存
  function saveSjisTextFile(content: string, fileName: string) {

    const encoder = new TextEncoder();
    const buffer = encoder.encode(content);
    const sjisArray = new Uint8Array(Encoding.convert(buffer, { to: 'SJIS', from: 'AUTO' }));

    const blob = new Blob([sjisArray], { type: 'text/csv;charset=shift-jis;' });

    if (window.navigator.msSaveBlob) {
      window.navigator.msSaveBlob(blob, fileName);
    } else {
      var a = document.createElement("a");
      a.href = URL.createObjectURL(blob);
      //a.target   = '_blank';
      a.download = fileName;
      document.body.appendChild(a) //  FireFox specification
      a.click();
      document.body.removeChild(a) //  FireFox specification
    }
  }

  //フォーム入力時の処理
  const onChangeExamNo = (examNo: string) => {

    setExamUIState({ ...examUIState, ExamNo: examNo });
  }

  const onChangeLevelTesttype = (levelTesttypeKey: number) => {

    setExamUIState({ ...examUIState, LevelTesttype: LevelTesttypeMaster[levelTesttypeKey] });
  }

  const onChangeLocationCode = (event: React.ChangeEvent<{ name?: string; value: any }>) => {

    setExamUIState({ ...examUIState, LocationCode: event.target.value });
  }



  // -----スタイルの宣言-----
  const classNames = useStyles();


  // -----state-----
  const [examStateValue, setExamStateValue] = React.useState<Exam>(initialExamStateValue);
  const [markStateValue, setMarkStateValue] = React.useState<MarkCSV[]>([initialMarkStateValue]);
  const [examUIState, setExamUIState] = React.useState<ExamUIData>(initialExamUIData);


  // -----use effefct-----
  React.useEffect(() => {
    //試験名を取得
    getExamData({ id: params.examId })
      .then((data: APIResponse<Exam[]>) => {
        if (data.errorCode !== 20000) {
          common.alertError(data.errorTitle, data.errorDetail);
          return;
        }

        if (data.value[0] !== null) setExamStateValue(data.value[0]);

      }).catch((err) => {
        alert(common.ResponseMessages.Error_GetExam);
      });


    //受験者解答情報を取得
    getAnswerData({ executionId: params.examId })
      .then((data: APIResponse<MarkCSV[]>) => {
        if (data.errorCode !== 20000) {
          common.alertError(data.errorTitle, data.errorDetail);
          return;
        }

        if (data.value[0] !== null) setMarkStateValue(data.value);

      }).catch((err) => {
        alert(common.ResponseMessages.Error_GetExam);
      });

  }, []);



  return (
    <Layout navLinks={normalLinks} buttons={
      [
        { text: goExamRegister.text, handler: () => { go({ pathname: goExamRegister.pathname, state: goExamRegister.state }) } }
      ]
    }>
      <div className={classNames.homeRoot}>

        <div className={classNames.centerItemStyle}>
          <h4>{examStateValue.name} 解答データのエクスポート</h4>
        </div>

        <div className={classNames.mainContainer}>
          <table>
            <div className={classNames.formItemStyle}>
                第 <TextField onChange={(event) => onChangeExamNo(event.target.value)} value={examUIState.ExamNo} /> 回
            </div>

            <div className={classNames.formItemStyle}>
              {
                LevelTesttypeMaster.map(data => (
                  <>
                    <Radio checked={examUIState.LevelTesttype.key === data.key} onClick={(event) => onChangeLevelTesttype(data.key)} />
                    {data.valueJp}<br></br>
                  </>
                ))
              }
            </div>

            <div className={classNames.formItemStyle}>
              <div className={classNames.labelStyle}><label>場所コード</label></div>
              {/* 場所コードに選択の必要がある場合は以下を使用する */}
              <Select value={examUIState.LocationCode} onChange={onChangeLocationCode} inputProps={{ name: 'LocationCode' }}>
                {
                  LocationCodeMaster.map(data => (
                    <MenuItem style={{ fontSize: common.FONT_SIZE.mainText }} key={data} value={data}>{data}</MenuItem>
                  ))
                }
              </Select>
              {/* 場所コードが固定値の場合は以下を使用する */}
              {/*<TextField style={{ width: "100px" }} fullWidth value={examUIState.LocationCode} required InputProps={{
                readOnly: true, disableUnderline: true
              }} />*/}
            </div>

            <div className={classNames.formItemStyle}>
              <div className={classNames.labelStyle}><label>会場コード</label></div>
              <TextField style={{ width: "100px" }} fullWidth value={examUIState.VenueCode} required InputProps={{
                readOnly: true, disableUnderline: true
              }} />
            </div>
          </table>
        </div>

        <div>
          <ButtonContainer className={classNames.buttonStyle} style={{ marginTop: "50px" }}>
            <DefaultBackButton className="backBtn" onClick={() => { go({ pathname: "/exam/index/" + params.examId }) }}>戻る</DefaultBackButton>
            <DefaultButton onClick={getAcceptedCSV}>エクスポート</DefaultButton>
          </ButtonContainer>
        </div>

      </div>

    </Layout>
  );
}

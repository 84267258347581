import React from 'react';

import { createUseStyles } from 'react-jss';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useLocation } from "react-router-dom";

import { Layout } from '../Layout';
import * as common from "../../common";
import { transitLearnerValue,initialLearnerStateValue} from '../Learner/Index';
import {normalLinks,goExamRegister} from '../NavLinks/NavLinks';
import { Learner ,APIResponse} from '../../react-app-env';
import { Exam } from '../../react-app-env';
import {DefaultButton,ButtonContainer,DefaultBackButton}  from '../StylesUI/CommonLayouts';


// 引数で生成するCSSを定義してuseStyles関数を生成
const useStyles = createUseStyles({
    // 第一階層はCSSクラス名（実際は後ろに重複防止の接尾辞がつく）
    // homeRoot => .homeRoot-1-2-3 など
    learnerRegisterRoot: {
        fontSize: common.FONT_SIZE.mainText,
        minWidth:"450px",
        // 入れ子にする場合は＆を使う
        // https://cssinjs.org/jss-plugin-nested?v=v10.4.0
        "& h1": {
            color: "green"
        },
        "& .xxxxx": {
            color: "green"
        },
        "& h4": {
            color: "#003f71",
            fontWeight: "bold",
            marginBottom:"30px",
            fontSize:common.FONT_SIZE.title
        }
    },
    upperContents: {
        display: "flex",
        justifyContent: "space-between"
    },
    linkStyle: {
        opacity: 0.9,
        backgroundColor:"#4378b6", 
        padding:"6px 16px",
        borderRadius: "4px",
        color:"white",
        marginRight:"5px",
        marginTop:"25px"
    },
    buttonStyle: {
        "& button":{
            fontSize:common.FONT_SIZE.button,
            marginTop:"30px"
        },
        "& button:hover": {
            backgroundColor:"#003f71", 
        },
        "& button:focus": {
            outline:"none !important", 
        },
        "& .backBtn:hover": {
            backgroundColor:"#003f71",
            color:"white" 
        }
    },
    formStyle: {
        height:"8rem",
        marginBottom:"2.5rem",
        display:"flex",
        justifyContent: "flex-start",
        flexDirection: "column",
        "& td": {
            width:"260px"
        },
        "& input ,textarea ,.MuiFormHelperText-root ":{
            fontSize:common.FONT_SIZE.mainText,
        }
    },
    labelStyle: {
        paddingRight: "60px"
    }
    
});



export function LearnerRegister() {
    // -----定数の定義-----
    //遷移元から受け取る受験者データ
    const location:transitLearnerValue=useLocation();
    const learnerValue:Learner=location.state;


    // -----共通関数の宣言-----
    const {
        params,
        api,
        go // 画面遷移 
    } = common.useCommon();

    

    // -----API-----
     // 受験者データを取得する
    function getLearnerData(args?: any):Promise<APIResponse<Learner[]>> {
        return api("/api/o-learner", "GET", args)
    }

    //試験データを取得する
    function getExamData(args?: any):Promise<APIResponse<Exam[]>> {
        return api("/api/o-exam", "GET", args)
    }


    // -----Handler-----
    //登録ボタンクリック時の処理
    const onClickRegister=(url:string)=>{

        //初めてクリックされたら各エラー表示をONにする
        if(!isClicked){
            setIsError({
                learnerNumber: true,
                email:   true
            } )
            setisClicked(true);
        } 

        const isValidLearnerNumber=validateUserName(learnerStateValue.learnerNumber);
        const isValidEmail=common.validateEmailFunc(learnerStateValue.email).correctFlag;

        if(isValidLearnerNumber&&isValidEmail) go(getTransitionInfo(url));
    }


    //フォーム入力時の処理
    const handleInput=(event: React.ChangeEvent<HTMLInputElement>)=>{

        const name = event.target.name as keyof typeof learnerStateValue;

        setLearnerStateValue({
            ...learnerStateValue,
            [name]:event.target.value
        }) 

        switch(name){
            case "learnerNumber":if(validateUserName(event.target.value)) break; 
                            else if(!isError.learnerNumber){
                                setIsError({
                                            ...isError,
                                            [name]:true
                                        }); break;
                            }; break;

            case "email":   const validateResult = common.validateEmailFunc(event.target.value);
                            if(validateResult.correctFlag) {
                                setAddressErrorMessages(validateResult.errorMessage);
                                break;
                            } 
                            else if(!isError.email){
                            setIsError({
                                        ...isError,
                                        [name]:true
                                    }); 
                            }; 
                            setAddressErrorMessages(validateResult.errorMessage);
                            break;
        }
    }



    //各項目でフォーカスアウトした時にエラー表示をONにする
    const handleBlur=(event: React.FocusEvent<HTMLInputElement>)=>{

        const name = event.target.name as keyof typeof learnerStateValue;

        setIsError({
            ...isError,
            [name]:true
        })

    }


    //入力項目のバリデーション
    const validateUserName=(learnerId:string)=>{
        if(learnerId!==""){
            setLearnerNumberErrorMessages("");
            return true;
        }else{
            setLearnerNumberErrorMessages("入力して下さい");
            return false;
        }
    }


    // -----汎用関数定義-----
    //確認ページへの遷移時のJSONデータ返す(登録or変更)
    function getTransitionInfo(url:string):transitLearnerValue{
        const info={ 
            pathname: url,
            state : {   
                        userName:learnerStateValue.userName,
                        executionId:Number(params.examId),
                        learnerNumber:learnerStateValue.learnerNumber ,
                        email:learnerStateValue.email,
                        acceptDatetime:learnerStateValue.acceptDatetime ,
                        noticeDatetime:learnerStateValue.noticeDatetime ,
                        executionStartDatetime:learnerStateValue.executionStartDatetime ,
                        executionEndDatetime:learnerStateValue.executionEndDatetime ,
                        checkDate:learnerStateValue.checkDate ,
                        checkResult:learnerStateValue.checkResult,
                        modified:learnerStateValue.modified,
                        checkUser:learnerStateValue.checkUser,
                        deleteFlag:learnerStateValue.deleteFlag
                    } 
            } ;
        return info;
    }


     //確認ページへの遷移時のJSONデータ返す(削除)
     function getTransitionDeleteInfo(url:string):transitLearnerValue{
        const info={ 
            pathname: url,
            state : {   
                        userName:learnerStateValue.userName,
                        executionId:Number(params.examId),
                        learnerNumber:learnerStateValue.learnerNumber ,
                        email:learnerStateValue.email,
                        acceptDatetime:learnerStateValue.acceptDatetime ?? "",
                        noticeDatetime:learnerStateValue.noticeDatetime ?? "",
                        executionStartDatetime:learnerStateValue.executionStartDatetime ?? "",
                        executionEndDatetime:learnerStateValue.executionEndDatetime ?? "",
                        checkDate:learnerStateValue.checkDate ?? "",
                        checkResult:learnerStateValue.checkResult,
                        modified:learnerStateValue.modified,
                        checkUser:learnerStateValue.checkUser,
                        deleteFlag:learnerStateValue.deleteFlag
                    } 
            } ;
        return info;
    }


    // -----スタイルの宣言-----
    const classNames = useStyles();


    


    // -----state-----
    const [state, setState] = React.useState({ type: "", url: "" });
    const [examName, setExamName]= React.useState("");
    const [learnerStateValue,setLearnerStateValue]=React.useState<Learner>(initialLearnerStateValue);
    const [learnerNumberErrorMessages,setLearnerNumberErrorMessages]=React.useState("");
    const [addressErrorMessages,setAddressErrorMessages]=React.useState("");
    const [isError,setIsError]=React.useState({
        learnerNumber: false,
        email:false
    } )
    //登録、変更ボタンが初めて押されたかどうか
    const [isClicked,setisClicked]=React.useState(false);

    
    // -----use effefct-----
    //遷移元によって登録、変更に分岐
    React.useEffect(() => {
        switch (params.type) {
            case "register": 

                setState({ type: "登録",url: "/learner/confirm/register/"+params.examId+"/"+params.userName});
                setLearnerNumberErrorMessages("入力して下さい");
                setAddressErrorMessages("入力して下さい");
                if(learnerValue!==undefined) setLearnerStateValue(learnerValue);
                break;

            case "edit": 

                setState({ type: "編集", url: "/learner/confirm/edit/"+params.examId+"/"+params.userName});
                //URL遷移とlocationがある場合で判定して分岐
                if(learnerValue!==undefined) setLearnerStateValue(learnerValue);
                else {
                     getLearnerData({executionId:params.examId,showDeleted:false ,userName:params.userName})
                     .then((data:APIResponse<Learner[]>) => {

                        if (data.errorCode !==20000) {
                            common.alertError(data.errorTitle , data.errorDetail);
                            return;
                        }

                        if(data.value !== null) setLearnerStateValue(data.value[0]);

                     }).catch((err) => {
                         alert(common.ResponseMessages.Error_GetLearner);
                     });   
                 }
        }


        //試験名を取得
        getExamData({id:params.examId})
        .then((data:APIResponse<Exam[]>) => {

            if (data.errorCode !==20000) {
                common.alertError(data.errorTitle , data.errorDetail);
                return;
            }

            if(data.value !== null) setExamName(data.value[0].name);

        }).catch((err) => {
            alert(common.ResponseMessages.Error_GetExam);
        });
    }, []);



    
    return (
        <Layout navLinks={normalLinks} buttons={
            [
              { text: goExamRegister.text, handler: () => { go({pathname:goExamRegister.pathname,state:goExamRegister.state })} }
            ]
          }>
            <div className={classNames.learnerRegisterRoot}>
                
                <div className={classNames.upperContents}>

                    <h4>{examName} 受験者 {state.type}</h4>

                    <div className={classNames.buttonStyle}>
                        <Button variant="contained" color="primary"  hidden={state.type==="登録"} 
                            className={classNames.linkStyle} onClick={() => {  go(getTransitionDeleteInfo("/learner/confirm/delete/"+params.examId+"/"+params.userName)) }}
                            >削除</Button>
                    </div>  

                </div>

                <table>
                    <tbody>
                        <tr style={{pointerEvents :"none"}} className={classNames.formStyle} hidden={state.type==="登録"}>
                            <th ><label>受験者ID</label></th>
                            <td><TextField fullWidth value={learnerStateValue.userName} name="userName"  InputProps={{
                                readOnly: true,disableUnderline: true 
                            }}/></td>
                        </tr>

                        <tr className={classNames.formStyle}>
                            <th className={classNames.labelStyle}><label>ID（受験番号）</label></th>
                            <td><TextField fullWidth value={learnerStateValue.learnerNumber} name="learnerNumber" onChange={handleInput} onBlur={handleBlur}
                                 required error={isError.learnerNumber ?learnerNumberErrorMessages!="":false} helperText={isError.learnerNumber ? learnerNumberErrorMessages:""}/></td>
                        </tr>

                        <tr className={classNames.formStyle}>
                            <th><label>メールアドレス</label></th>
                            <td><TextField fullWidth value={learnerStateValue.email} name="email" onChange={handleInput} onBlur={handleBlur}
                                error={isError.email ?addressErrorMessages!="":false} helperText={isError.email ? addressErrorMessages:""}/></td>
                        </tr>
                    </tbody>
                </table>      

                <ButtonContainer className={classNames.buttonStyle}>
                    <DefaultBackButton className="backBtn" onClick={() => go({pathname:"/learner/index/"+params.examId,state:initialLearnerStateValue})}>戻る</DefaultBackButton>
                    <DefaultButton onClick={()=>{onClickRegister(state.url)}}>入力内容を確認する</DefaultButton>
                </ButtonContainer>

            </div>
        </Layout>
    );
}

import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

import { ExamTable } from '../Table/ExamTable';
import * as common from "../../common";
import { Exam ,APIResponse ,Learner} from '../../react-app-env';
import { Layout } from '../Layout';
import {normalLinks,goExamRegister} from '../NavLinks/NavLinks';
import {CustomModal} from "../StylesUI/CustomModal"
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

// 引数で生成するCSSを定義してuseStyles関数を生成
const useStyles = makeStyles((theme) => ({
    // 第一階層はCSSクラス名（実際は後ろに重複防止の接尾辞がつく）
    // homeRoot => .homeRoot-1-2-3 など
    topRoot: {
        fontSize: common.FONT_SIZE.mainText,
        // 入れ子にする場合は＆を使う
        // https://cssinjs.org/jss-plugin-nested?v=v10.4.0
        "& h1": {
            color: "green"
        },
        "& .xxxxx": {
            color: "green"
        },
        "& h4": {
            color: "#003f71",
            fontWeight: "bold",
            marginBottom:"30px",
            fontSize:common.FONT_SIZE.title
        },
        "& .MuiInputBase-input":{
            fontSize: common.FONT_SIZE.mainText
        }
    },
    modalText:{
        fontSize: "1.8rem",
        fontWeight: "bold",
        padding:"2rem 0",
        color:"#4378b6",
        "& .MuiTypography-body1":{
            fontSize: "2rem",
            fontWeight: "bold",
            color:"black",
        }
    }
}));



export function Top() {

    // -----共通関数の宣言-----
    const {
        go, // 画面遷移 
        api  // API呼び出し
    } = common.useCommon();


    // -----API-----
    // 試験データを取得する
    function getExamData(args?: any):Promise<APIResponse<Exam[]>> {
        return api("/api/o-exam", "GET", args)
    };

    // 受験者データを取得する
    function getLearnerData(args?: any):Promise<APIResponse<Learner[]>> {
        return api("/api/o-learner", "GET", args)
    }



    // -----汎用関数宣言-----
    function getLatestExamData(data:Exam[]){
        return data?.filter((exam:Exam)=>{
            const d1=new Date();
            const d2 =new Date(exam.executeStart);
            const remainingTime=d2.getTime() - d1.getTime();
            const weeks=remainingTime / (1000 * 60 * 60 * 24 * 7 );
            return ( -1 <= weeks && weeks <= 1 ) 
        })
    }

    const handleClickSortOrder = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>)=>{
        //e.currentTarget.blur();
        if(sortOrder === "ASC") setSortOrder("DESC");
        else setSortOrder("ASC");
    }


    //指定された試験の現状の登録受験者数を返す
    const getNumberOfLearner = (id:number)=>{

        getLearnerData({executionId:id})
        .then((data:APIResponse<Learner[]>) => {
            if (data.errorCode !== 20000) {
                common.alertError(data.errorTitle , data.errorDetail);
                return;
            }

            if( data.value !== null) setNumberOfLearner( data.value.length )
           
        }).catch((err) => {
            alert(common.ResponseMessages.Error_GetLearner);
        });
    }


    const getDraftExamList=(latestExam:Exam[])=>{
        let flag = false;
        let draftExams = [];
        let draftExamList = <div></div>;

        for (const exam of latestExam) {
            if(exam.status === 0){
                flag = true ;
                draftExams.push(exam.name);
            }
        }

        draftExamList = listArrangeStrings(draftExams);
 
        return draftExamList;

    }

    const needsOpenModal = (latestExam:Exam[])=>{
        let flag = false;

        for (const exam of latestExam) {
            if(exam.status === 0){
                flag = true ;
            }
        }
        return flag;
    }


    //受け取った文字列配列内の文字列をリストエレメントにして返す
    const listArrangeStrings = (stringArray:string[])=>{

        const stringsList =
                <div className={classNames.modalText}>
                    <p>以下の試験が下書きの状態です</p>
                        <List>
                            {stringArray.map((string, idx) => (
                                <ListItem key={idx}>
                                    <ListItemText
                                        primary = { "・ " + string}
                                    />
                                </ListItem>
                            ))}
                        </List>
                    <p>試験開始までに「問題・解答の編集」から、試験を正書状態に変更して下さい</p>
                </div>  

        return stringsList;
    }



    //試験データの並び順を決める
    function switchOrder(examArray:Exam[]){
        let retVal:Exam[] = [];

        switch (sortOrder) {
            case "ASC":
                retVal = ascSortExam(examArray);
                break;
        
            case "DESC":
                retVal = descSortExam(examArray);
                break;
        }

        return retVal;

    }



    //降順ソート
    function descSortExam(examArray:Exam[]){
        return examArray.sort(function(a:Exam,b:Exam){
          const aTime = new Date(a.executeStart).getTime();
          const btime = new Date(b.executeStart).getTime();
  
          if(aTime > btime){
            return -1 ;
          }
          if(aTime > btime){
            return 1 ;
          }
          return 0;
  
        })
  
      }
  
      //昇順ソート
      function ascSortExam(examArray:Exam[]){
        return examArray.sort(function(a:Exam,b:Exam){
          const aTime = new Date(a.executeStart).getTime();
          const btime = new Date(b.executeStart).getTime();
  
          if(aTime < btime){
            return -1 ;
          }
          if(aTime > btime){
            return 1 ;
          }
          return 0;
  
        })
  
      }



    // -----スタイルの宣言-----
    const classNames = useStyles();


    


    // -----state-----
    const [latestExamData, setLatestExamData] = React.useState<Exam[]>([]);

    const [numberOfLearner, setNumberOfLearner] = React.useState(0);

    const[isLoaded,setIsLoaded] = React.useState(false);

    const[sortOrder,setSortOrder] = React.useState("ASC");

    const [openModal, setOpenModal] = React.useState(false);

    const [modalText, setModalText] = React.useState(<div></div>);


    // -----use effefct-----
    // 初回だけ実行する処理
    React.useEffect(() => {
        // サーバーから認証してデータ取得
        getExamData()
            .then((data:APIResponse<Exam[]>) => {
                if (data.errorCode !==20000) {
                    common.alertError(data.errorTitle , data.errorDetail);
                    return;
                }

                if(data.value !== null){

                    const examData = data.value.filter((exam:Exam)=>{
                        return (exam.deleteFlag===false) 
                    })

                    const latestExamData = getLatestExamData(examData);
    
                    setLatestExamData(latestExamData);

                    if(needsOpenModal(latestExamData)){
                        setModalText(getDraftExamList(latestExamData));
                        setOpenModal(true);
                    }
  
                }

                setIsLoaded(true);

                
            }).catch((err) => {
                alert(common.ResponseMessages.Error_GetExam);
            });
    }, []);
   



    return (
        <Layout navLinks={normalLinks} buttons={
            [
              { text: goExamRegister.text, handler: () => { go({pathname:goExamRegister.pathname,state:goExamRegister.state })} }
            ]
          }>
            {isLoaded ? 

            <div className={classNames.topRoot} >

                <h4>試験一覧(試験開始日時が現在の前後1週間の試験のみ表示)</h4>

                <CustomModal
                    openModal = {openModal}
                    setOpenModal = {setOpenModal}
                    modalFunc = {()=>{}}
                    isNotice = {true}
                >
                    {modalText}
                </CustomModal>

                <div>
                    <ExamTable sortOrder={sortOrder} handleClickSortOrder={handleClickSortOrder} switchOrder={switchOrder} examData={latestExamData} getNumberOfLearner={getNumberOfLearner} numberOfLearner = {numberOfLearner}/>
                </div>
                
            </div>

            : <div>Loading...</div>

            }
        </Layout>
    );
}

import React from 'react';
import * as common from "../../common";

import { Layout } from '../Layout';
import { createUseStyles } from 'react-jss';
import {normalLinks,goExamRegister} from '../NavLinks/NavLinks';
import { APIResponse} from '../../react-app-env';
import {saveTextFile} from "../../common";
import { Exam ,learnerCSV} from '../../react-app-env';
import {DefaultButton,ButtonContainer,DefaultBackButton}  from '../StylesUI/CommonLayouts';
import Button from '@material-ui/core/Button';



// 引数で生成するCSSを定義してuseStyles関数を生成
const useStyles = createUseStyles({
    // 第一階層はCSSクラス名（実際は後ろに重複防止の接尾辞がつく）
    // homeRoot => .homeRoot-1-2-3 など
    homeRoot: {
        fontSize: common.FONT_SIZE.mainText,
        textAlign:"center",
        // 入れ子にする場合は＆を使う
        // https://cssinjs.org/jss-plugin-nested?v=v10.4.0
        "& h1": {
            color: "green"
        },
        "& .xxxxx": {
            color: "green"
        },
        "& h4": {
            color: "#003f71",
            fontWeight: "bold",
            marginBottom:"30px",
            fontSize:common.FONT_SIZE.title
        }
    },
    linkStyle: {
        opacity: 0.9,
        backgroundColor:"#4378b6", 
        padding:"6px 16px",
        borderRadius: "4px",
        color:"white",
        marginRight:"5px"
    },
    buttonStyle: {
        display:"block",
        "& button:hover": {
            backgroundColor:"#003f71", 
        },
        "& .backBtn:hover": {
            backgroundColor:"#003f71",
            color:"white" 
        },
        "& button":{
            fontSize:common.FONT_SIZE.button,
        },
        "& button:focus": {
            outline:"none !important", 
        }
    },
    downloadButtonStyle:{
        "& button": {
            fontSize:common.FONT_SIZE.button,
            width:"26.5rem",
        },
        "& button:focus": {
            outline:"none !important", 
        }
    },
    links: {
        "& :hover":{
            color:"white",
            opacity: 1,
            textDecoration:"none"
        },
        marginBottom:"20px"
    },
    iconStyle :{
       marginBottom:"4px",
    },
    buttonContainer:{
        display: "flex",
        justifyContent: "space-between"
    }

});




export function CsvDownload() {

    // -----共通関数の宣言-----
    const {
        params,
        go, // 画面遷移 
        api  // API呼び出し
    } = common.useCommon();



    // -----API-----
    //試験IDを引数に渡して受験者リスト取得
    function getLearnerCSV(args?: any):Promise<APIResponse<learnerCSV[]>> {
        return api("/api/o-learnerCSV", "GET", args)
    }

    //試験データを取得する
    function getExamData(args?: any):Promise<APIResponse<Exam[]>> {
        return api("/api/o-exam", "GET", args)
    }



    // -----Handler-----
    //受付済みの受験者データリストをダウンロード
    const getAcceptedCSV=()=>{
        getLearnerCSV({executionId:Number(params.examId),unreceived:false})
        .then((data:APIResponse<learnerCSV[]>) => {

            if (data.errorCode !==20000) {
                common.alertError(data.errorTitle , data.errorDetail);
                return;
            }

            const learnerData:any = data.value.map(data=>(
                {"受験番号" : data.learnerNumber,
                "受験者ID" : data.learnerID,
                "メールアドレス": data.email,
                "問題集URL": data.statementUrl
                
                }
            ));

            getAcceptedLearners(learnerData)

        }).catch((err) => {
            alert(common.ResponseMessages.Error_GetLearner);
        });
    }


    //未受付の受験者データリストをダウンロード
    const getNotAcceptedCSV=()=>{
        getLearnerCSV({executionId:Number(params.examId),unreceived:true})
        .then((data:APIResponse<learnerCSV[]>) => {

            if (data.errorCode !==20000) {
                common.alertError(data.errorTitle , data.errorDetail);
                return;
            }

            const learnerData:any = data.value.map(data=>(
                {"受験番号" : data.learnerNumber,
                 "受験者ID" : data.learnerID,
                 "メールアドレス": data.email,
                 "ワンタイムURL": data.onetimeUrl
                }
            ));

            getNotAcceptedLearners(learnerData);

        }).catch((err) => {
            alert(common.ResponseMessages.Error_GetLearner);
        });
    }




    // // -----汎用関数の宣言-----
    const getAcceptedLearners = (learnerData: any) => {
        // CSVヘッダー
        const acceptedHeader: string[] = ["受験番号", "受験者ID", "メールアドレス", "問題集URL"];
        common.toCsv(learnerData, { noHeader: false, cols: acceptedHeader })
        .then((result)=>{

            saveTextFile(result,examName+"受付済み受験者リスト.csv");

        }).catch((err) => {
            alert(err);
        }); 
    };

    

    
    const getNotAcceptedLearners = (learnerData: any) => {
        // CSVヘッダー
        const notAcceptedHeader: string[] = ["受験番号", "受験者ID", "メールアドレス", "ワンタイムURL"];
        common.toCsv(learnerData, { noHeader: false, cols: notAcceptedHeader })
        .then((result)=>{

            saveTextFile(result,examName+"未受付受験者リスト.csv");

        }).catch((err) => {
            alert(err);
        }); 
    };
  

    // -----スタイルの宣言-----
    const classNames = useStyles();

   



    // -----state-----
    const [examName, setExamName]= React.useState("");
    
    
    // -----use effefct-----
    React.useEffect(() => {
        //試験名を取得
        getExamData({id:params.examId})
        .then((data:APIResponse<Exam[]>) => {
            if (data.errorCode !==20000) {
                common.alertError(data.errorTitle , data.errorDetail);
                return;
            }

            if(data.value !== null) setExamName(data.value[0].name);

        }).catch((err) => {
            alert(common.ResponseMessages.Error_GetExam);
        });
    }, []);
  


    
    return (
        <Layout navLinks={normalLinks} buttons={
            [
              { text: goExamRegister.text, handler: () => { go({pathname:goExamRegister.pathname,state:goExamRegister.state })} }
            ]
          } width="780px">
            <div className={classNames.homeRoot}>

                <h4 >{examName} 受験者CSVダウンロード</h4>

                <div style={{marginTop:"50px"}}  >

                    <div className={classNames.downloadButtonStyle}>
                        <Button  onClick={getAcceptedCSV} variant="contained" color="primary">
                            受付済みリストのダウンロード
                        </Button>
                    </div>

                    <div style={{marginTop:"20px"}} className={classNames.downloadButtonStyle}>
                        <Button onClick={getNotAcceptedCSV} variant="contained" color="secondary">
                            未受付リストのダウンロード
                        </Button>
                    </div>

                </div>

                <ButtonContainer className={classNames.buttonStyle} style={{marginTop:"50px"}}>
                    <DefaultBackButton className="backBtn" onClick={() => { go({pathname:"/learner/index/"+params.examId}) }}>戻る</DefaultBackButton>
                </ButtonContainer>
                
            </div>
        </Layout>
    );
}

import React from 'react';

import {  } from 'react-jss';
import { Choice, Exam as IExam,ExamQuestion,Question as IQuestion } from '../../../react-app-env';
import {RichEditor} from '../../RichEditor/RichEditor'
import Button from '@material-ui/core/Button';
import * as common from "../../../common";
import { Box } from '@material-ui/core';
import { DefaultButton ,ButtonContainer,DefaultBackButton} from '../../StylesUI/CommonLayouts';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    // 第一階層はCSSクラス名（実際は後ろに重複防止の接尾辞がつく）
    // homeRoot => .homeRoot-1-2-3 など
    columnContainer: {
        display:"flex",
        flexDirection:"column",
        alignItems:"center",
        "& button":{
            fontSize:common.FONT_SIZE.button
        },
        "& button:focus": {
            outline:"none !important", 
        },
        "& h3":{
            fontSize:common.FONT_SIZE.title,
            fontWeight:"bold",
            color: "#00468b"
        },
        "& #warning":{
            fontWeight:"bold",
            fontSize:"1.8rem",
            color:"red"
        }
    },
    rowContainer: {
        display: "flex",
        justifyContent:"center"
    },
    buttonStyle: {
        "& button:hover": {
            backgroundColor:"#003f71", 
        },
        "& button:focus": {
            outline:"none !important", 
        },
        "& .backBtn:hover": {
            backgroundColor:"#003f71",
            color:"white" 
        }
    }
  }));
  
export function PreviewEndScreen(props:{isFinishedTest:boolean ,examQuestion : ExamQuestion ,returnQuestionPage?:any}) {

    const {
        history,
        params,
        getUser, // ログインユーザー取得
        go, // 画面遷移 
        logout, // 強制ログアウト
        api  // API呼び出し
    } = common.useCommon();

    const classNames = useStyles();
    // コンポーネント関数内でuseStyles呼び出してCSS生成
    // 戻り値で、クラス名を取得
    return (
        <div className={classNames.columnContainer}>
            <Box marginBottom={4}>
                <h3>{props.examQuestion.name}</h3>
            </Box>
            <Box marginBottom={4}>
                <RichEditor  defaultValue={props.examQuestion.endBodyHtml}  readOnly={true}/>
            </Box>
            <Box marginBottom={4}>
                <p id="warning">※一度解答を終了すると再解答は出来ません</p>
            </Box>
            <div className={classNames.rowContainer}>
                <ButtonContainer hidden={props.isFinishedTest} style={{marginRight:"8px"}} className={classNames.buttonStyle}><DefaultBackButton className="backBtn" onClick={props.returnQuestionPage} >解答へ戻る</DefaultBackButton></ButtonContainer>
                <ButtonContainer className={classNames.buttonStyle}><DefaultButton onClick={()=>{go("/edit-question/" + props.examQuestion.executionId + "/start-screen")}} >試験を終了する</DefaultButton></ButtonContainer>
            </div>
        </div>
    );
}

import React from 'react';

import { Choice, Exam as IExam,ExamQuestion,Extension,Question as IQuestion } from '../../../react-app-env';
import {RichEditor} from '../../RichEditor/RichEditor'
import * as common from "../../../common";
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Radio from '@material-ui/core/Radio';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import { Accordion, AccordionDetails, Divider, Typography } from '@material-ui/core';
import { DefaultButton ,ButtonContainer,DefaultBackButton} from '../../StylesUI/CommonLayouts';
import { makeStyles } from '@material-ui/core/styles';
import AccordionSummary from '@material-ui/core/AccordionSummary/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import MediaQuery from "react-responsive";
import {PreviewEndScreen} from "./EndScreen"
import {CustomModal} from "../../StylesUI/CustomModal"
import { MobileDisplay } from './MobileDisplay'

interface Selection extends Choice {
    isSelected: boolean
  }

const useStyles = makeStyles((theme) => ({
    previewQuestionRoot: {
        flexGrow:1,
        fontSize: common.FONT_SIZE.mainText,
        backgroundColor:"white",
        "& button":{
            fontSize:common.FONT_SIZE.button
        },
        "& button:focus": {
            outline:"none !important", 
        },
        "& #questionHeaderNum":{
            margin: "0 0 12px",
            padding:" 4px 16px",
            backgroundColor: "#003f71",
            color: "#ffffff",
            fontSize: "22px",
        },
        "& #sectionQuestionHeaderNum":{
            margin: "0 0 12px",
            padding:" 4px 16px",
            backgroundColor: "#003f71",
            color: "#ffffff",
            fontSize: "22px",
            border:"solid 1px white"
        }
    },
    sideBarTextStyle:{
      padding: "8px",
      paddingLeft: "28px",
      color:"#003f71",
      fontSize: common.FONT_SIZE.sideBarText,
      lineBreak: 'anywhere'
    },
    sideBarTextStyle_minWidth:{
      padding: "4px",
      color:"#003f71",
      fontSize: common.FONT_SIZE.sideBarText,
      lineBreak: 'anywhere'
    },
    hasParentQuestion: {
        paddingLeft: "5px",
        color:"#003f71",
        "& .headNum":{
            paddingLeft: "42px",
            fontWeight:"bold"
        }
    },
    singleQuestion:{
        paddingLeft: "5px",
        color:"#003f71",
        "& .headNum":{
            fontWeight:"bold",
        }
    },
    activePage:{
        background: "#7fb911",
        "& *":{
            color:"white",
        }
    },
    notActivePage:{
      "& > :hover":{
        background: "rgba(127,185,17,.3)",
      }
    },
    readPage: {
      background: "#ffa500",
      "& > :hover": {
        background: "#cd853f",
      }
    },
    answeredPage: {
      background: "rgba(100, 149, 237, 1)",
      "& > :hover": {
        background: "#4682b4",
      }
    },
    sideBar:{
        maxHeight:"600px",
        //minWidth: "160px",
        overflow:"auto",
        "& span":{
            fontSize:common.FONT_SIZE.sideBarText
        }
    },
    choiceSelect:{
        "& .choiceTextStyle": {
            whiteSpace: "pre-wrap",
            wordBreak: "break-all",
            width: "90%",
            color:"black",
            fontSize: common.FONT_SIZE.choiceText,
            lineHeight:"2rem"
        },
        "& li":{
            fontSize: common.FONT_SIZE.choiceText,
            marginBottom:"15px"
        },
        "& svg":{
            fontSize: "28px",
        },
        "& .MuiInput-underline.Mui-disabled:before":{
            borderBottomStyle:"solid"
        }
    },
    buttonContainer : {
        display:"flex",
        justifyContent:"space-between",
        fontSize:common.FONT_SIZE.mainText,
    },
    sideBarButtonPosition : {
        display:"flex",
        justifyContent:"center",
        marginTop:"50px",
        "& .div-root-40":{
            width:"80%",
        },
        "& button":{
            width:"100%",
            borderRadius: "8px",
        }
    },
    sideBarListStyle : {
        maxWidth:"300px",
    },
    leftElementStyle : {
        flexBasis:"75%"
    },
    rowContainer: {
        display:"flex",
        justifyContent:"flex-start",
        alignItems:"center",
        //width:"65%",
        "& h4":{
            cursor:"pointer"
        }
    },
    expandText:{
        fontSize: common.FONT_SIZE.mainText,
        fontWeight:"bold",
        color:"#003f71",
        flexBasis: '10%',
        textAlign:"right"
    },
    expandSizeStyle:{
        "& .MuiAccordionDetails-root": {
            padding: 0
        },
        marginBottom:"1.8rem"
    },
    buttonStyle: {
        "& button":{
            borderRadius: "8px",
        },
        "& button:hover": {
            backgroundColor:"#003f71", 
            outline:0
        },
        "& .backBtn:hover": {
            backgroundColor:"#003f71",
            color:"white" ,
            outline:0
        }
    },
    questionSizeContainer : {
        fontSize:common.FONT_SIZE.mainText,
        marginBottom:"12px"
    },
    modalText: {
        fontSize:"2rem",
        fontWeight:"bold",
        padding:"2rem 0",
        textAlign:"center",
        "& #warning":{
            fontSize:"1.6rem",
            color:"red"
        }
    },
    mobileScreenDemo: {
        width: '27rem', //16:9
        height: '48rem', //16:9
        alignItems: 'center',
        display: 'block',
        border: "solid 1px",
        backgroundColor: "#ffffff",
    }
    
  }));

export function PreviewQuestion(props: { previewSelectionChoices: number[], setPreviewSelectionChoices: any, previewAnswerText: { id: number; text: string; }[], setPreviewAnswerText: any, isFinishedTest: boolean, remainingTime_sec: number, exam: ExamQuestion, originQuestion: IQuestion[], question: IQuestion[], curQuestion: IQuestion, setQuestionHeadnum: any, countDown: any, resetRemainingTime: any, extensions: Extension[] }) {

    const {
        history,
        params,
        getUser, // ログインユーザー取得
        go, // 画面遷移 
        logout, // 強制ログアウト
        api  // API呼び出し
    } = common.useCommon();
    
    const getNextPageUrl = (exam:ExamQuestion, curQuestion:IQuestion, question:IQuestion[])=>{
        let nextQuestion =  question.filter(q=>q.editOrder === (curQuestion.editOrder +1))[0];

        if(!nextQuestion && exam.endViewFlag === false){
            //openConfirmModal(returnEditQuestionPage ,<div className={classNames.modalText}>プレビューを終了しますか？</div>);
            openConfirmModal(returnEditQuestionPage ,confirmModalElement);
            return "";
        }

        if(!nextQuestion){
            return "end-screen";
        }

        return "/edit-question/" + props.exam.executionId +  "/preview/"+(props.curQuestion.editOrder + 1);
    }

    const returnEditQuestionPage = ()=>{
        go("/edit-question/" + props.exam.executionId + "/start-screen")
    }

    const getPreviousPageUrl = (exam:ExamQuestion, curQuestion:IQuestion, question:IQuestion[])=>{
        let previousQuestion =  question.filter(q=>q.editOrder === (curQuestion.editOrder -1))[0];

        if(!previousQuestion && exam.startViewFlag === false){
            return "/edit-question/" + props.exam.executionId + "/start-screen";
        }

        if(!previousQuestion){
            return "/edit-question/" + props.exam.executionId + "/preview/start-screen";
        }

        return "/edit-question/" + props.exam.executionId +  "/preview/"+(props.curQuestion.editOrder - 1);
    }

    const handleChange=(choiceId : number ,editOrder : number)=>{
        
        let newSelection = props.previewSelectionChoices.concat();
        if (props.curQuestion.answerType ===0) {
            newSelection = removeRadioSelections(newSelection,props.curQuestion.choices);

            newSelection.push(choiceId);
        }

        if (props.curQuestion.answerType === 1){
            if (props.previewSelectionChoices.filter(s=>s === choiceId).length ===0){
                newSelection.push(choiceId);
            }else{
                newSelection = newSelection.filter(s=>s !== choiceId);
            }
        }

        if(!answeredQuestions.includes(String(editOrder))){
            let orders = answeredQuestions.concat();
            orders.push(String(editOrder));
            setAnsweredQuestions(orders);
        }

        props.setPreviewSelectionChoices(newSelection);
    }

    // 解答テキスト入力時の処理
    const handleTextChange = (questionId: number, inputText: string, editOrder: number) => {

      let newAnswerText = props.previewAnswerText.concat();

      // 同じ問題の以前の入力は削除
      newAnswerText = newAnswerText.filter(a => a.id !== questionId);
      newAnswerText.push({ id: questionId, text: inputText });

      // 問題を既読にする
      if (!answeredQuestions.includes(String(editOrder))) {
        let orders = answeredQuestions.concat();
        orders.push(String(editOrder));
        setAnsweredQuestions(orders);
      }

      props.setPreviewAnswerText(newAnswerText);
    }

    const onClickEndAnswer =(exam:ExamQuestion)=>{

        if(!exam.endViewFlag){
            go("/edit-question/" + props.exam.executionId + "/start-screen");
            return;
        }

        setIsViewEndScreen(true);
    }


    const onClickNext = ()=>{
        const url = getNextPageUrl(props.exam,props.curQuestion,props.question);
        if(url === "end-screen" ) setIsViewEndScreen(true);
        else if(url !== "") go(url);
    }

    const onClickProcessOnFinishedTest = ()=>{
        if(!props.exam.endViewFlag){
            go("/edit-question/" + props.exam.executionId + "/start-screen");
            return;
        }

        setIsViewEndScreen(true);
    }



    const getChoiceNumberingTypeString = (choiceNumberingType : number)=>{
        switch(choiceNumberingType){
            case 0:
                return "decimal"
            case 1:
                return "katakana"
            case 2:
                return "lower-roman"
            case 3:
                return "upper-roman"
            case 4:
                return "none"
            default:
                break;
          }
    }
    
    const onChangeExpand = ()=>{
        setState({...state,isExpanded:!state.isExpanded})
    }

    // -----汎用関数定義-----
    const resetSelectionChoices = ()=>{
        let newSelection = [-1];
        props.setPreviewSelectionChoices(newSelection);

        let newAnswerText = [{ id: -1, text: "" }];
        props.setPreviewAnswerText(newAnswerText);
    }


    const removeRadioSelections = (selectionArray:number[],choices:Choice[])=>{
        let newSelection = selectionArray.concat();

        for (const choice of choices) {
            newSelection = newSelection.filter(s=>s !== choice.id);
        }
        return newSelection;
    }


    function QuestionViewOrderByAsc(a:{viewOrder:number},b:{viewOrder:number}){
        if( a.viewOrder < b.viewOrder ) return -1;
        if( a.viewOrder > b.viewOrder ) return 1;
        return 0;
    };

    function QuestionEditOrderByAsc(a:{editOrder:number},b:{editOrder:number}){
      if (a.editOrder < b.editOrder) return -1;
      if (a.editOrder > b.editOrder) return 1;
      return 0;
    };

    function choicesOrderByViewOrder(a:{viewOrder:number},b:{viewOrder:number}){
        if( a.viewOrder < b.viewOrder ) return -1;
        if( a.viewOrder > b.viewOrder ) return 1;
        return 0;
    };

    function choicesOrderByEditOrder(a:{editOrder:number},b:{editOrder:number}){
        if( a.editOrder < b.editOrder ) return -1;
        if( a.editOrder > b.editOrder ) return 1;
        return 0;
    };

    //セクション開始画面、セクションではない問題かどうかを判定
    const isHiddenSubject = (question:IQuestion)=>{
        let flag = false ;

        if(question.sectionFlag) flag = true ;
        else if(question.parentQuestionId === 0) flag = true ;
        else flag = false 

        return flag ;
    }


    const endButtonText = () => {
        if (props.curQuestion?.editOrder === Math.max(...props.question.map(q=>q.editOrder))) {
            //return props.exam.endViewFlag ? "解答終了確認へ" : "解答を終了する"
            return props.exam.endViewFlag ? "解答終了確認へ" : "試験を終了する"
        } else {
            return "次の問題へ"
        }
    }

    const returnQuestionPage = ()=>{
        setIsViewEndScreen(false);
    }

    const questionHeaderText = () => {
        return props.setQuestionHeadnum(props.curQuestion, props.originQuestion.sort(props.exam.shuffleFlag ? QuestionEditOrderByAsc : QuestionViewOrderByAsc));
    }

    //--------modal関係----------

    const handleOpen = () => {
        setOpenModal(true);
    };


    //処理の確認モーダルを表示(実行する関数を渡す)
    const openConfirmModal = (executionFunc:any ,modalText:JSX.Element)=>{
        setModalFunction(()=>executionFunc);
        setModalText(modalText);
        handleOpen();
    }


    //未既読機能関係
    //対象のセクション内の子問題をすべて返す
    const getChildrenQuestion = (parentId:number ,questionsArray:IQuestion[]) =>{
        let children = questionsArray.filter( q => q.parentQuestionId === parentId );
        return children;
    }

    const isAnsweredAllChildren = (parentId:number)=>{
        const children = getChildrenQuestion(parentId ,props.question)
        let isAnsweredAll = true;
    
        for (const child of children) {
          if( !answeredQuestions.includes(String(child.editOrder)) ) isAnsweredAll =false;
        }
    
        return isAnsweredAll;
    
    }

    const swichStyleByAnswerState = (question:IQuestion)=>{
        let style = classNames.notActivePage;
    
        if(question.sectionFlag){
          const isAnswerdAll = isAnsweredAllChildren(question.id);
          isAnswerdAll ? style = classNames.answeredPage : style = classNames.notActivePage ;
          return style;
        }
    
        if( readQuestionOrders.includes(String(question.editOrder))){
            style = classNames.readPage;
        }
        if( answeredQuestions.includes(String(question.editOrder))){
            style = classNames.answeredPage;
        }

        return style;
    
    }

    //文字列の行数を返す関数
    const getRowsNum = (text:string) => {
        let lineNum = text.match(/\n/g);
        let rowsNum = 1;
        if(lineNum != null){
            rowsNum = lineNum.length + 1;
        }
        return rowsNum
    }



    const classNames = useStyles();

    const [state, setState] = React.useState({
        questionId: props.curQuestion?.id,
        activeItemKey: parseInt(params.order),
        parentText: props.curQuestion?.parentQuestionId === 0 ? "" : props.exam.questions?.filter(q => q.id === props.curQuestion?.parentQuestionId)[0].subjectText,//"",
        parentHtml: props.curQuestion?.parentQuestionId === 0 ? "" : props.exam.questions?.filter(q => q.id === props.curQuestion?.parentQuestionId)[0].bodyHtml,//"",
        isExpanded: true
    });
    if (state.questionId !== props.curQuestion?.id){
        const parentText = props.curQuestion?.parentQuestionId === 0 ? "" : props.exam.questions?.filter(q=>q.id === props.curQuestion?.parentQuestionId)[0].subjectText;
        const parentHtml = props.curQuestion?.parentQuestionId === 0 ? "" : props.exam.questions?.filter(q=>q.id === props.curQuestion?.parentQuestionId)[0].bodyHtml;
        setState({...state, questionId:props.curQuestion.id,activeItemKey:parseInt(params.order),parentText:parentText, parentHtml:parentHtml});
    }
    const [isViewEndScreen,setIsViewEndScreen]=React.useState<boolean>(false);

    //モーダル関係
    const [openModal, setOpenModal] = React.useState(false);
    const [modalFunction, setModalFunction] = React.useState<any>();
    const [modalText, setModalText] = React.useState<JSX.Element>(<div/>);

    //未既読関係
    const [readQuestionOrders, setReadQuestionOrders] = React.useState<string[]>([]);
    const [answeredQuestions, setAnsweredQuestions] = React.useState<string[]>([]);


    // -----use effefct-----
    React.useEffect(() => {

        let timerId: any;
        timerId = props.countDown(props.remainingTime_sec);

        return () => {
            clearInterval(timerId);
            props.resetRemainingTime()
            resetSelectionChoices()
        }

    }, [])

    React.useEffect(() => {

        if(!readQuestionOrders.includes(params.order)){
            let orders = readQuestionOrders.concat();
            orders.push(params.order);
            setReadQuestionOrders(orders);
        }

    }, [params.order])


    const confirmModalElement = <div className={classNames.modalText}>
                                    <p>解答を終了してもよろしいですか？</p>
                                    <p id="warning">※一度解答を終了すると再解答は出来ません</p>
                                </div>


    return (
        <div className={classNames.previewQuestionRoot}>
            {isViewEndScreen 
                ?
                    <PreviewEndScreen isFinishedTest={props.isFinishedTest} examQuestion={props.exam} returnQuestionPage={returnQuestionPage}/>
                :
                <Grid container >
                    <Grid item sm={2} md={2} lg={2} > {/*サイドバー*/} 
                        
                        <MediaQuery query="(max-width: 1200px)">
                            <List className={classNames.sideBar} style={{ maxHeight: "600px", overflow: "auto" }}>
                                {
                                    props.question.sort(props.exam.shuffleFlag ? QuestionEditOrderByAsc : QuestionViewOrderByAsc).map(question=>(
                                        <Box key={question.id} className={question.editOrder === state.activeItemKey ? classNames.activePage: swichStyleByAnswerState(question)}>
                                            <ListItem key={question.id} button >
                                                <Grid container direction="column" className={classNames.sideBarTextStyle_minWidth} onClick={props.isFinishedTest ? onClickProcessOnFinishedTest : () => { go("/edit-question/" + props.exam.executionId + "/preview/" + question.editOrder) }}>
                                                    <Grid item xs={12} style={question.sectionFlag ? { width: "fit-content", border: "solid 1px", padding: "0px 5px", fontWeight: "bold" } : { fontWeight: "bold" }} className={question.sectionFlag ? "sectionHeadNum" : "headNum"}> {props.setQuestionHeadnum(question, props.originQuestion.sort(props.exam.shuffleFlag ? QuestionEditOrderByAsc : QuestionViewOrderByAsc))} </Grid>
                                                            <Grid item xs={12} > {question.subjectText + "..."} </Grid>
                                                </Grid>
                                            </ListItem>
                                            <Divider variant="fullWidth"  />
                                        </Box>
                                    ))
                                }
                            </List>

                            <Box className={classNames.sideBarButtonPosition} hidden={props.exam.choiceDeviceFlag}>
                                <ButtonContainer  className={classNames.buttonStyle}> 
                                    <DefaultButton onClick={props.isFinishedTest ? onClickProcessOnFinishedTest : props.exam.endViewFlag ? () => onClickEndAnswer(props.exam) : () => { openConfirmModal(() => { onClickEndAnswer(props.exam) }, confirmModalElement) }}>
                                        {/* {props.exam.endViewFlag ? "解答終了確認へ" : "解答を終了する"}*/}
                                        {props.exam.endViewFlag ? "解答終了確認へ" : "試験を終了する"}
                                    </DefaultButton> 
                                </ButtonContainer>
                            </Box>

                        </MediaQuery>

                        <MediaQuery query="(min-width: 1200px)">
                            <List className={classNames.sideBar} style={{ maxHeight: "600px", overflow: "auto" }}>
                                {
                                    props.question.sort(props.exam.shuffleFlag ? QuestionEditOrderByAsc : QuestionViewOrderByAsc).map(question=>(
                                        <Box key={question.id} className={question.editOrder === state.activeItemKey ? classNames.activePage: swichStyleByAnswerState(question)}>
                                            <ListItem key={question.id} button >
                                                <Grid container direction="column" className={ classNames.sideBarTextStyle } onClick={ props.isFinishedTest ? onClickProcessOnFinishedTest : () => { go("/edit-question/" + props.exam.executionId+"/preview/" + question.editOrder)}}>
                                                    {/* <Grid item  xs={12} style={{ fontWeight: "bold"}} className={question.sectionFlag ? "sectionHeadNum" : "headNum"}> {props.setQuestionHeadnum(question,props.originQuestion.sort(orderByAsc))} </Grid> */}
                                                    <Grid item xs={12} style={question.sectionFlag ? { width: "fit-content", border: "solid 1px", padding: "0px 5px", fontWeight: "bold" } : { fontWeight: "bold" }} className={question.sectionFlag ? "sectionHeadNum" : "headNum"}> {props.setQuestionHeadnum(question, props.originQuestion.sort(props.exam.shuffleFlag ? QuestionEditOrderByAsc : QuestionViewOrderByAsc))} </Grid>
                                                            <Grid item  xs={12} > {question.subjectText + "..."} </Grid>
                                                </Grid>
                                            </ListItem>
                                            <Divider variant="fullWidth"  />
                                        </Box>
                                    ))
                                }
                            </List>

                            <Box className={classNames.sideBarButtonPosition} hidden={props.exam.choiceDeviceFlag}>
                                <ButtonContainer className={classNames.buttonStyle}>
                                    <DefaultButton onClick={props.isFinishedTest ? onClickProcessOnFinishedTest : 
                                        /*props.exam.endViewFlag ? () => onClickEndAnswer(props.exam) : () => { openConfirmModal(() => { onClickEndAnswer(props.exam) }, confirmModalElement) }}>{props.exam.endViewFlag ? "解答終了確認へ" : "解答を終了する"}*/
                                        props.exam.endViewFlag ? () => onClickEndAnswer(props.exam) : () => {openConfirmModal(() => { onClickEndAnswer(props.exam) }, confirmModalElement)}}>{props.exam.endViewFlag ? "解答終了確認へ" : "試験を終了する"}
                                    </DefaultButton>
                                </ButtonContainer>
                            </Box>

                        </MediaQuery>

                    </Grid> 

                        <div>
                            <Divider orientation="vertical" style={{marginRight:"32px",marginLeft:"32px"}}/>
                        </div>

                        <CustomModal
                            openModal= {openModal}
                            setOpenModal= {setOpenModal}
                            modalFunc= {modalFunction}
                        >
                            {modalText}
                        </CustomModal>


                    <Grid item sm={6} md={6} lg={6}> {/*問題・選択肢*/}
                        
                        <Grid container hidden={isHiddenSubject(props.curQuestion)} className={classNames.expandSizeStyle} sm={12} md={12} lg={12} /*justify="space-between"*/ >
                            <Grid item style={{ width: "100%" }}>
                                <Accordion style={{ display: props.curQuestion?.parentQuestionId === 0 ? "none" : "block" }} expanded={state.isExpanded} onClick={onChangeExpand}>
                                    <AccordionSummary expandIcon={state.isExpanded ? <ExpandLessIcon style={{ fontSize: "3rem" }} /> : <ExpandMoreIcon style={{ fontSize: "3rem" }} />}>
                                            <Typography style={{flexBasis: '90%',fontSize:common.FONT_SIZE.mainText}} >{props.curQuestion?.sectionFlag ? props.curQuestion?.subjectText : (props.curQuestion?.parentQuestionId === 0 ? props.curQuestion?.subjectText : state.parentText ) }</Typography>
                                            <span className={classNames.expandText}>{state.isExpanded ? "閉じる" : "開く" }</span>
                                        </AccordionSummary>
                                    <AccordionDetails>
                                        <RichEditor key={props.curQuestion?.id} defaultValue={props.curQuestion?.sectionFlag ? props.curQuestion?.bodyHtml : (props.curQuestion?.parentQuestionId === 0 ? props.curQuestion?.bodyHtml : state.parentHtml ) }  readOnly={true} />
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>

                            {/*<Grid item  >
                                <ButtonContainer className={classNames.buttonStyle}><DefaultButton onClick={()=>{go("/edit-question/" + props.exam?.executionId + "/start-screen")}}>プレビュー終了</DefaultButton></ButtonContainer>
                            </Grid>*/}
                                    
                        </Grid>

                        <Grid container className={classNames.questionSizeContainer} sm={12} md={12} lg={12} /*justify="space-between"*/>

                            <Grid item style={{width: "100%"} }>
                                <h2 id={"questionHeaderNum"}><span style={props.curQuestion.sectionFlag ? { border: "solid 1px", padding: "0px 10px" } : {}}>{questionHeaderText()}</span></h2>
                                <RichEditor  key={props.curQuestion?.id} defaultValue={props.curQuestion?.bodyHtml}  readOnly/>
                            </Grid>

                            {/*<Grid  item hidden={!isHiddenSubject(props.curQuestion)} >
                                <ButtonContainer className={classNames.buttonStyle}><DefaultButton onClick={()=>{go("/edit-question/" + props.exam?.executionId + "/start-screen")}}>プレビュー終了</DefaultButton></ButtonContainer>
                            </Grid>*/}

                        </Grid>


                        <Grid item sm={12} md={12} lg={12} hidden={props.exam.choiceDeviceFlag}>
                            <Box marginBottom={4}>
                                {
                                    props.curQuestion?.sectionFlag === false ? 

                                    <ul className={classNames.choiceSelect} style={{ listStyleType: getChoiceNumberingTypeString(props.curQuestion.choiceNumberingType) }}>
                                        {
                                            props.curQuestion.answerType > 1 ?
                                            props.curQuestion.answerType === 2 ?
                                                <input type="text" pattern="[0-9]+" inputMode="numeric"
                                                value={props.previewAnswerText.filter(a => a.id === props.curQuestion.id)[0]?.text ?? ""}
                                                onChange={(event) => handleTextChange(props.curQuestion.id, event.target.value, props.curQuestion.editOrder)} />
                                                :
                                                <input type="text"
                                                value={props.previewAnswerText.filter(a => a.id === props.curQuestion.id)[0]?.text ?? ""}
                                                onChange={(event) => handleTextChange(props.curQuestion.id, event.target.value, props.curQuestion.editOrder)} />
                                            :
                                            props.curQuestion?.choices?.filter(c=>c.deleteFlag===false).sort(props.curQuestion.choiceShuffleFlag ? choicesOrderByViewOrder : choicesOrderByEditOrder).map((choice : Choice)=>(
                                            <li key={choice.id} >
                                                <div className={classNames.rowContainer} >
                                                    <Box >
                                                        {
                                                            props.curQuestion.answerType === 0 ? 
                                                                    <Radio
                                                                        onClick={()=>{handleChange(choice.id ,props.curQuestion.editOrder)}} 
                                                                        value={choice.id}
                                                                        checked={props.previewSelectionChoices.filter(s=>s === choice.id).length > 0}
                                                                    />
                                                                :
                                                                    <Checkbox
                                                                        onClick={()=>{handleChange(choice.id ,props.curQuestion.editOrder)}} 
                                                                        value={choice.id}
                                                                        checked={props.previewSelectionChoices.filter(s=>s === choice.id).length > 0}
                                                                    />
                                                        }
                                                    </Box>

                                                    <div className="choiceTextStyle" onClick={()=>{handleChange(choice.id ,props.curQuestion.editOrder)}}>
                                                        {choice.bodyText}   
                                                    </div>

                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                    : <div></div>
                                }
                            </Box>
                        </Grid>

                        <Grid container>

                            <Grid item sm={12} md={12} lg={12} >
                                <Box className={classNames.buttonContainer} style={{ marginTop: "2rem" }}>
                                    {
                                        props.curQuestion?.editOrder === 1 ? <div></div> : <ButtonContainer className={classNames.buttonStyle}> <DefaultBackButton className="backBtn" style={{marginRight:"8px"}} onClick={ props.isFinishedTest ? onClickProcessOnFinishedTest : ()=>{go(getPreviousPageUrl(props.exam,props.curQuestion,props.question))}} ><ArrowBackIosIcon/><span style={{paddingBottom:"0.2rem"}}>&nbsp;前の問題へ</span></DefaultBackButton> </ ButtonContainer>
                                    }

                                    <ButtonContainer className={classNames.buttonStyle} hidden={props.exam.choiceDeviceFlag && endButtonText() !== "次の問題へ"}> <DefaultButton onClick={props.isFinishedTest ? onClickProcessOnFinishedTest : onClickNext} ><span style={{ paddingBottom: "0.2rem" }}>{endButtonText()}&nbsp;</span><span hidden={endButtonText() == "試験を終了する"}><ArrowForwardIosIcon /></span></DefaultButton> </ ButtonContainer>
                                </Box>
                            </Grid>

                        </Grid>
                    </Grid>

                        <div>
                            <Divider orientation="vertical" style={{ marginRight: "32px", marginLeft: "32px" }} />
                        </div>

                    <Grid item sm={1} md={2} lg={2} > {/*サイドバー右*/} 
                        <Grid item style={{ marginBottom: "64px" }} >
                            <ButtonContainer className={classNames.buttonStyle}><DefaultButton onClick={() => { go("/edit-question/" + props.exam?.executionId + "/start-screen") }}>プレビュー終了</DefaultButton></ButtonContainer>
                        </Grid>

                        <div className={classNames.mobileScreenDemo} hidden={!props.exam.choiceDeviceFlag}>
                            <MobileDisplay
                                endViewFlag={props.exam.endViewFlag}
                                remainingTime={props.remainingTime_sec}
                                extensions={props.extensions}
                                selectionChoices={props.previewSelectionChoices}
                                handleSelectionChange={handleChange}
                                choices={props.curQuestion?.choices?.filter(c => c.deleteFlag === false).sort(props.curQuestion.choiceShuffleFlag ? choicesOrderByViewOrder : choicesOrderByEditOrder)}
                                answerText={props.previewAnswerText.filter(a => a.id === props.curQuestion.id)[0]?.text ?? ""}
                                handleTextChange={handleTextChange}
                                question={props.curQuestion}
                                endButtonText={endButtonText()}
                                headerText={questionHeaderText()}
                                onClickBackBtn={props.isFinishedTest ? onClickProcessOnFinishedTest : () => { go(getPreviousPageUrl(props.exam, props.curQuestion, props.question)) }}
                                onClickNextBtn={props.isFinishedTest ? onClickProcessOnFinishedTest : onClickNext}
                                onClickFinishBtn={props.isFinishedTest ? onClickProcessOnFinishedTest : props.exam.endViewFlag ? () => onClickEndAnswer(props.exam) : () => { openConfirmModal(() => { onClickEndAnswer(props.exam) }, confirmModalElement) }}
                            />
                        </div>
                    </Grid>
                </Grid>
            }
        </div>
 
    );
}

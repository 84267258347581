import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import TextField from '@material-ui/core/TextField';
import { useLocation } from "react-router-dom";

import { Layout } from '../Layout';
import * as common from "../../common";
import { transitUserValue,initialUserStateValue} from '../AdminUser/Index';
import {normalLinks,goExamRegister} from '../NavLinks/NavLinks';
import { User ,APIResponse} from '../../react-app-env';
import {DefaultButton,ButtonContainer,DefaultBackButton}  from '../StylesUI/CommonLayouts';

// 引数で生成するCSSを定義してuseStyles関数を生成
const useStyles = makeStyles((theme) => ({
    adminUserEditRoot: {
        fontSize: common.FONT_SIZE.mainText,
        minWidth:"450px",
        // 入れ子にする場合は＆を使う
        // https://cssinjs.org/jss-plugin-nested?v=v10.4.0
        "& .formStyle-1-2-4": {
            height:"auto"
        },
        "& h4": {
            color: "#003f71",
            fontWeight: "bold",
            marginBottom:"30px",
            fontSize:common.FONT_SIZE.title
        }
    },
    upperContents: {
        display: "flex",
        justifyContent: "space-between"
    },
    inputFormStyle:{
        height:60
    },
    linkStyle: {
        opacity: 0.9,
        backgroundColor:"#4378b6", 
        padding:"6px 16px",
        borderRadius: "4px",
        color:"white",
        marginRight:"5px",
        marginTop:"25px"
    },
    buttonStyle: {
        "& button":{
            fontSize:common.FONT_SIZE.button,
            marginTop:"30px"
        },
        "& button:hover": {
            backgroundColor:"#003f71", 
        },
        "& button:focus": {
            outline:"none !important", 
        },
        "& .backBtn:hover": {
            backgroundColor:"#003f71",
            color:"white" 
        }
    },
    formStyle: {
        height:"8rem",
        marginBottom:"2.5rem",
        display:"flex",
        justifyContent: "flex-start",
        flexDirection: "column",
        "& td": {
            width:"260px"
        },
        "& input ,textarea ,.MuiFormHelperText-root":{
            fontSize:common.FONT_SIZE.mainText,
        },
        "& #remark":{
            padding:5,
            lineHeight:1.2
        },
        "& .MuiOutlinedInput-multiline":{
            padding:"12px 14px"
        }
    },
    labelStyle: {
        paddingRight: "60px"
    }
}));



export function AdminUserEdit() {

    // -----定数の定義-----
    //遷移元から受け取る主催者データ
    const location:transitUserValue=useLocation();
    const userValue:User=location.state;


    // -----API-----
    // 主催者ユーザーを取得する
    function getAdminUser(args?:any):Promise<APIResponse<User[]>>{
        return api("/api/o-user", "GET",args)
    }


    // -----共通関数の宣言-----
    const {
        go, // 画面遷移
        api,
        params 
    } = common.useCommon();

    

    // -----Handler-----
    //変更ボタンクリック時の処理
    const onClickUpdate=(url:string)=>{
        //初めてクリックされたら各エラー表示をONにする
        if(!isClicked){
            setIsError({
                name: true,
                nameKana:true,
                phoneNumber:true,
                email:true, 
            } )
            setisClicked(true);
        } 

        if(validateAllForms()) go(getTransitionInfo(url));

    }

    //フォーム入力時の処理
    const handleInput=(event: React.ChangeEvent<HTMLInputElement>)=>{

        const name = event.target.name as keyof typeof userStateValue;

        setUserStateValue({
            ...userStateValue,
            [name]:event.target.value
        })

        switch(name){
            case "name":if(validateUserName(event.target.value)) break; 
                            else if(!isError.name){
                                setIsError({
                                            ...isError,
                                            [name]:true
                                        }); break;
                            }; break;

            case "nameKana":if(validateNameKana(event.target.value)) break; 
                            else if(!isError.nameKana){
                            setIsError({
                                        ...isError,
                                        [name]:true
                                    }); break;
                            }; break;

            case "phoneNumber":if(validatePhoneNumber(event.target.value)) break; 
                                else if(!isError.phoneNumber){
                                setIsError({
                                            ...isError,
                                            [name]:true
                                        }); break;
                                }; break;

            case "email": if(validateEmail(event.target.value)) break; 
                        else if(!isError.email){
                        setIsError({
                                    ...isError,
                                    [name]:true
                                }); break;
                        }; break;
            }
    }


    //各項目のバリデーション関数
    const validateUserName=(userName:string)=>{
        if(userName!==""){
            setUserNameErrorMessage("");
            return true;
        }else{
            setUserNameErrorMessage("入力して下さい");
            return false;
        }
    }


    const validateNameKana=(nameKana:string)=>{
        if(nameKana.match(/^[ァ-ヶー　]*$/)&&nameKana!==""){
            setNameKanaErrorMessage("");
            return true;
        }
        else if(nameKana===""){
            setNameKanaErrorMessage("入力して下さい");
            return false;
        }else{
            setNameKanaErrorMessage("全角カナで入力して下さい")
        }
        return false;
    }


    const validatePhoneNumber=(phoneNumber:string)=>{
        if(phoneNumber.match(/^0\d{1,4}-?\d{1,4}-?\d{4}$/)){
            setPhoneNumberErrorMessage("");
            return true
        }else if(phoneNumber===""){
            setPhoneNumberErrorMessage("入力して下さい");
        }else{
            setPhoneNumberErrorMessage("正しい電話番号を入力して下さい(半角数字)");
        }
        return false;
    }


    const validateEmail=(email:string)=>{
        if(email.match(/^([a-zA-Z0-9]|[-!#$%&'*+/=?^_`{}|~])+([a-zA-Z0-9\.]|[-!#$%&'*+/=?^_`{}|~])*@([a-zA-Z0-9-])+((\.(?!\.))|[a-zA-Z0-9-]+)+([a-zA-Z0-9-])$/ )){
            setEmailErrorMessage("");
            return true;
        }else if(email===""){
            setEmailErrorMessage("入力して下さい");
        }else{
            setEmailErrorMessage("正しいメールアドレスを入力して下さい");
        }
        return false;
    }


        

    //確認ページへの遷移時のJSONデータ返す(変更時)
    function getTransitionInfo(url:string):transitUserValue{
        const info={ 
            pathname: url,
            state : userStateValue
        } ;
            return info;
    }


    const validateAllForms=()=>{
        let flag = true;

        if(!validateUserName(userStateValue.name)) flag=false;

        if(!validateNameKana(userStateValue.nameKana)) flag=false;

        if(!validatePhoneNumber(userStateValue.phoneNumber)) flag=false;

        if(!validateEmail(userStateValue.email)) flag=false;


        return flag
       
    }



    // -----スタイルの宣言-----
    const classNames = useStyles();



    // -----state-----
    const [userStateValue,setUserStateValue]=React.useState<User>(initialUserStateValue)
    const [userNameErrorMessage,setUserNameErrorMessage]=React.useState("");
    const [nameKanaErrorMessage,setNameKanaErrorMessage]=React.useState("");
    const [phoneNumberErrorMessage,setPhoneNumberErrorMessage]=React.useState("");
    const [emailErrorMessage,setEmailErrorMessage]=React.useState("");
    const [isError,setIsError]=React.useState({
        name: false,
        nameKana:false,
        phoneNumber:false,
        email:false
    } )
    //登録、変更ボタンが初めて押されたかどうか
    const [isClicked,setisClicked]=React.useState(false);
    

    // -----use effefct-----
    React.useEffect(() => {
        if(userValue!==undefined) setUserStateValue(userValue);
        else {
            //APIたたいてデータ持ってきてsetExamStateValue
            getAdminUser({ id: params.userId, showDeleted: true })
            .then((data:APIResponse<User[]>) => {

                if (data.errorCode !== 20000) {
                    common.alertError(data.errorTitle , data.errorDetail);
                    return;
                }
 
                if(data.value !== null) setUserStateValue(data.value[0])
                
            }).catch((err) => {
                alert(common.ResponseMessages.Error_GetAdminUser);
            });
            }
    }, []);


    
    
    return (
        <Layout navLinks={normalLinks} buttons={
            [
              { text: goExamRegister.text, handler: () => { go({pathname:goExamRegister.pathname,state:goExamRegister.state })} }
            ]
          }>
            <div className={classNames.adminUserEditRoot}>

                <div className={classNames.upperContents}>

                    <h4>主催者ユーザ 編集</h4>

                    <ButtonContainer className={classNames.buttonStyle}>
                        <DefaultButton onClick={() => { go(getTransitionInfo("/admin-user/confirm/delete/" + params.userId)) }}>{userStateValue === undefined ? "無効化" : userStateValue.deleteFlag ? "有効化" : "無効化"}</DefaultButton>
                    </ButtonContainer>

                </div>

                <table>
                    <tbody>
                        <tr className={classNames.formStyle}>
                            <th><label>氏名</label></th>
                            <td><TextField style={{width:"200px"}} fullWidth value={userStateValue.name} name="name" onChange={handleInput} 
                                error={isError.name ?userNameErrorMessage!="":false} helperText={isError.name ? userNameErrorMessage:"" }/></td>
                        </tr>

                        <tr className={classNames.formStyle}>
                            <th><label>フリガナ</label></th>
                            <td><TextField style={{width:"200px"}} fullWidth value={userStateValue.nameKana} name="nameKana" onChange={handleInput} 
                            error={isError.nameKana ?nameKanaErrorMessage!="":false} helperText={isError.nameKana ? nameKanaErrorMessage:"" }/></td>
                        </tr>

                        <tr className={classNames.formStyle}>
                            <th><label>電話番号</label></th>
                            <td><TextField style={{width:"150px"}} fullWidth value={userStateValue.phoneNumber} name="phoneNumber" onChange={handleInput} 
                            error={isError.phoneNumber ?phoneNumberErrorMessage!="":false} helperText={isError.phoneNumber ? phoneNumberErrorMessage:"" }/></td>
                        </tr>

                        <tr className={classNames.formStyle}>
                            <th className={classNames.labelStyle}><label>メールアドレス</label></th>
                            <td><TextField fullWidth value={userStateValue.email} name="email" onChange={handleInput} 
                            error={isError.email ?emailErrorMessage!="":false} helperText={isError.email ? emailErrorMessage:"" } InputProps={{
                                readOnly: true,disableUnderline: true
                            }}/></td>
                        </tr>

                        <tr className={classNames.formStyle}>
                            <th><label>備考</label></th>
                            <td><TextField id="remark" fullWidth value={userStateValue.note} name="note" 
                            variant="outlined"  multiline rows={3} onChange={handleInput} /></td>
                        </tr>

                    </tbody>
                </table>

                <ButtonContainer style={{marginTop:"50px"}} className={classNames.buttonStyle}>
                    <DefaultBackButton className="backBtn" onClick={() => { go(getTransitionInfo("/admin-user/index"))}}>戻る</DefaultBackButton>
                    <DefaultButton  onClick={() => { onClickUpdate("/admin-user/confirm/edit/"+params.userId) }}>入力内容を確認する</DefaultButton>
                </ButtonContainer>
                
            </div>
        </Layout>

    );
}

import React from 'react';
import { createUseStyles } from 'react-jss';
import TextField from '@material-ui/core/TextField';
import { useLocation } from "react-router-dom";

import { Layout } from '../Layout';
import * as common from "../../common";
import { transitExamValue, initialExamStateValue } from '../Exam/Index';
import { normalLinks, goExamRegister } from '../NavLinks/NavLinks';
import { Exam, APIResponse } from '../../react-app-env';
import { DefaultButton, ButtonContainer, DefaultBackButton } from '../StylesUI/CommonLayouts';
import { CustomModal } from "../StylesUI/CustomModal";
import ReactPlayer from 'react-player';;

// 引数で生成するCSSを定義してuseStyles関数を生成
const useStyles = createUseStyles({
    // 第一階層はCSSクラス名（実際は後ろに重複防止の接尾辞がつく）
    // homeRoot => .homeRoot-1-2-3 など
    examConfirmRoot: {
        fontSize: common.FONT_SIZE.mainText,
        minWidth: "650px",
        // 入れ子にする場合は＆を使う
        // https://cssinjs.org/jss-plugin-nested?v=v10.4.0
        "& h1": {
            color: "green"
        },
        "& .xxxxx": {
            color: "green"
        },
        "& h4": {
            color: "#003f71",
            fontWeight: "bold",
            marginBottom: "30px",
            fontSize: common.FONT_SIZE.title
        },
        "& #acceptPeriodForm ,#executePeriodForm": {
            width: "30rem"
        }
    },
    linkStyle: {
        opacity: 0.9,
        backgroundColor: "#4378b6",
        padding: "6px 16px",
        borderRadius: "4px",
        color: "white",
        marginRight: "5px"
    },
    buttonStyle: {
        justifyContent: "space-between",
        width: "450px",
        "& button": {
            fontSize: common.FONT_SIZE.button,
            marginTop: "50px"
        },
        "& button:hover": {
            backgroundColor: "#003f71",
        },
        "& button:focus": {
            outline: "none !important",
        },
        "& .backBtn:hover": {
            backgroundColor: "#003f71",
            color: "white"
        }
    },
    formStyle: {
        display: "flex",
        justifyContent: "flex-start",
        flexDirection: "column",
        height: "8rem",
        marginBottom: "1rem",
        pointerEvents: "none",
        "& input ,textarea ,.MuiFormHelperText-root ,.MuiInputBase-root": {
            fontSize: common.FONT_SIZE.mainText,
        },
    },
    labelStyle: {
        paddingRight: "60px"
    },
    inputFormContainer: {
        display: "flex",
        justifyContent: "space-between",
        fontSize: 14,

    },
    modalText: {
        fontSize: "2rem",
        fontWeight: "bold",
        padding: "2rem 0",
        textAlign: "center"
    }
});


export function ExamConfirm() {
    // -----定数の定義-----
    //遷移元から受け取る試験データ
    const location: transitExamValue = useLocation();
    const confirmValue: Exam = location.state



    // -----共通関数の宣言-----
    const {
        params,
        go, // 画面遷移 
        api  // API呼び出し
    } = common.useCommon();



    // -----API-----

    // 試験データを送信する
    function sendExamData(args?: Exam) {
        return api("/api/o-exam", "POST", args)
    }

    // 試験データを変更する
    function updateExamData(args?: Exam) {
        return api("/api/o-exam", "PUT", args)
    }

    // 試験データを削除する
    function deleteExamData(args?: Exam) {
        return api("/api/o-exam", "DELETE", args)
    }


    // -----Handler-----
    //登録,編集,削除の実行
    function execute(url: string) {
        switch (params.type) {
            case "register": sendExamData(examStateValue)
                .then((data: APIResponse<Exam>) => {
                    if (data.errorCode !== 20000) {
                        common.alertError(data.errorTitle, data.errorDetail);
                        return;
                    }
                    handleModalOpen(common.ResponseMessages.Sucess_PostExam);
                }).catch((err) => {
                    alert(common.ResponseMessages.Error_PostExam);
                }); break;

            case "edit": updateExamData(examStateValue)
                .then((data: APIResponse<Exam>) => {
                    if (data.errorCode !== 20000) {
                        common.alertError(data.errorTitle, data.errorDetail);
                        return;
                    }
                    handleModalOpen(common.ResponseMessages.Sucess_PutExam);
                }).catch((err) => {
                    alert(common.ResponseMessages.Error_PutExam);
                }); break;

            case "delete": deleteExamData(examStateValue)
                .then((data: APIResponse<Exam>) => {
                    if (data.errorCode !== 20000) {
                        common.alertError(data.errorTitle, data.errorDetail);
                        return;
                    }
                    handleModalOpen(common.ResponseMessages.Sucess_DeleteExam);
                }).catch((err) => {
                    alert(common.ResponseMessages.Error_DeleteExam);
                }); break;
        }
    }

    const goBack = () => {
        switch (params.type) {
            case "register": go({ pathname: "/exam/register/" + confirmValue.id, state: examStateValue }); break;
            case "edit": go({ pathname: "/exam/edit/" + confirmValue.id, state: examStateValue }); break;
            case "delete": go("/exam/index"); break;
        }
    }



    function handleModalOpen(modalText: string) {
        setOpenModal(true);
        setModalText(modalText);
    }




    // -----スタイルの宣言-----
    const classNames = useStyles();





    // -----state-----
    const [type, setType] = React.useState("");
    const [examStateValue, setExamStateValue] = React.useState<Exam>(initialExamStateValue);
    //モーダル関係
    const [openModal, setOpenModal] = React.useState(false);
    const [modalText, setModalText] = React.useState("");


    // -----use effefct-----
    //遷移元によって登録、変更、削除に分岐
    React.useEffect(() => {
        if (confirmValue === undefined) {
            go("/exam/index");
        } else setExamStateValue(confirmValue);

        switch (params.type) {
            case "register": setType("登録"); break;
            case "edit": setType("編集"); break;
            case "delete": setType("削除"); break;
        }
    }, []);




    return (
        <Layout navLinks={normalLinks} buttons={
            [
                { text: goExamRegister.text, handler: () => { go({ pathname: goExamRegister.pathname, state: goExamRegister.state }) } }
            ]
        }>
            <div className={classNames.examConfirmRoot}>

                <h4>試験 {type} 確認画面</h4>

                <CustomModal
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                    modalFunc={() => { go({ pathname: "/exam/index", state: initialExamStateValue }) }}
                    isNotice={true}
                >
                    <div className={classNames.modalText}>{modalText}</div>
                </CustomModal>

                <table style={{ width: "550px" }}>
                    <tbody >
                        <tr className={classNames.formStyle}>
                            <th className={classNames.labelStyle}><label>試験名称</label></th>
                            <td><TextField fullWidth value={examStateValue.name} required InputProps={{
                                readOnly: true, disableUnderline: true
                            }} /></td>
                        </tr>

                        <tr className={classNames.formStyle}>
                            <th className={classNames.labelStyle}><label>試験種別</label></th>
                            <td><TextField style={{ width: "100px" }} fullWidth value={examStateValue.termType === 0 ? "一斉" : "任意"} required InputProps={{
                                readOnly: true, disableUnderline: true
                            }} /></td>
                        </tr>

                        <tr className={classNames.formStyle}>
                            <th className={classNames.labelStyle}><label>受付期間</label></th>

                            <td className={classNames.inputFormContainer} id="acceptPeriodForm">
                                <div ><TextField
                                    style={{ width: "145px" }}
                                    id=""
                                    value={common.dateFormat(examStateValue.acceptStart, 'L') + " " + common.dateFormat(examStateValue.acceptStart, 'LT')}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        readOnly: true, disableUnderline: true
                                    }}
                                    name="acceptStart" required
                                /></div>

                                <div style={{ marginRight: "20px" }}>～</div>

                                <div ><TextField
                                    style={{ width: "145px" }}
                                    id=""
                                    value={common.dateFormat(examStateValue.acceptEnd, 'L') + " " + common.dateFormat(examStateValue.acceptEnd, 'LT')}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        readOnly: true, disableUnderline: true
                                    }}
                                    name="acceptEnd" required
                                /></div>
                            </td>
                        </tr>

                        <tr className={classNames.formStyle}>
                            <th className={classNames.labelStyle}><label>実施期間</label></th>

                            <td className={classNames.inputFormContainer} id="executePeriodForm">

                                <div ><TextField
                                    style={{ width: "145px" }}
                                    id=""
                                    value={common.dateFormat(examStateValue.executeStart, 'L') + " " + common.dateFormat(examStateValue.executeStart, 'LT')}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        readOnly: true, disableUnderline: true
                                    }}
                                    name="executeStart" required
                                /></div>

                                <div style={{ marginRight: "20px" }}>～</div>

                                <div><TextField
                                    style={{ width: "145px" }}
                                    id=""
                                    value={common.dateFormat(examStateValue.executeEnd, 'L') + " " + common.dateFormat(examStateValue.executeEnd, 'LT')}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        readOnly: true, disableUnderline: true
                                    }}
                                    name="executeEnd" required
                                /></div>
                            </td>
                        </tr>

                        <tr className={classNames.formStyle}>
                            <th className={classNames.labelStyle}><label>試験時間</label></th>
                            <td><TextField style={{ width: "100px" }} fullWidth value={examStateValue.executeTime + " 分"} required InputProps={{
                                readOnly: true, disableUnderline: true
                            }} /></td>
                        </tr>

                        <tr className={classNames.formStyle}>
                            <th className={classNames.labelStyle}><label>最大人数</label></th>
                            <td><TextField style={{ width: "100px" }} fullWidth value={examStateValue.learnerMax + " 人"} required InputProps={{
                                readOnly: true, disableUnderline: true
                            }} /></td>
                        </tr>

                        <tr className={classNames.formStyle}>
                            <th style={{ paddingRight: "35px" }}><label>試験要項URL</label></th>
                            <td><TextField fullWidth value={examStateValue.guidanceUrl} InputProps={{
                                readOnly: true, disableUnderline: true
                            }} /></td>
                        </tr>

                        <tr className={classNames.formStyle}
                            style={{ height: examStateValue.advanceExplanationVideoUrl ? "auto" : undefined ,pointerEvents: "auto"}}
                        >
                            <th style={{ paddingRight: "35px" }}><label>試験説明動画URL</label></th>
                            <td>
                                <TextField fullWidth value={examStateValue.advanceExplanationVideoUrl} InputProps={{
                                    readOnly: true, disableUnderline: true
                                }} />
                                <div>
                                    <ReactPlayer
                                        url={examStateValue.advanceExplanationVideoUrl}
                                        crossOrigin="anonymous"
                                        controls
                                        playsInline
                                        height="254px"
                                        width="452px"
                                    />
                                </div>
                            </td>
                        </tr>

                    </tbody>
                </table>

                <ButtonContainer className={classNames.buttonStyle}>
                    <DefaultBackButton className="backBtn" onClick={goBack}>戻る</DefaultBackButton>
                    <DefaultButton onClick={() => { execute("/exam/index") }}>実行</DefaultButton>
                </ButtonContainer>

            </div>
        </Layout>
    );
}

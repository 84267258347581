import React from 'react';
import { createUseStyles } from 'react-jss';
import TextField from '@material-ui/core/TextField';

import { Layout } from '../Layout';
import * as common from "../../common";
import { useLocation } from "react-router-dom";
import { transitUserValue,initialUserStateValue} from '../AdminUser/Index';
import {normalLinks,goExamRegister} from '../NavLinks/NavLinks';
import { User } from '../../react-app-env';
import {DefaultButton,ButtonContainer,DefaultBackButton}  from '../StylesUI/CommonLayouts';

// 引数で生成するCSSを定義してuseStyles関数を生成
const useStyles = createUseStyles({
    // 第一階層はCSSクラス名（実際は後ろに重複防止の接尾辞がつく）
    // homeRoot => .homeRoot-1-2-3 など
    adminUserRegisterRoot: {
        fontSize: common.FONT_SIZE.mainText,
        minWidth:"650px",
        "& .formStyle-1-2-4": {
            height:"auto"
        },
        "& h4": {
            color: "#003f71",
            fontWeight: "bold",
            marginBottom:"30px",
            fontSize:common.FONT_SIZE.title
        }
    },
    linkStyle: {
        opacity: 0.9,
        backgroundColor:"#4378b6", 
        padding:"6px 16px",
        borderRadius: "4px",
        color:"white",
        marginRight:"5px",
        marginTop:"25px"
    },
    buttonStyle: {
        "& button":{
            fontSize:common.FONT_SIZE.button,
            marginTop:"30px"
        },
        "& button:hover": {
            backgroundColor:"#003f71", 
        },
        "& button:focus": {
            outline:"none !important", 
        },
        "& .backBtn:hover": {
            backgroundColor:"#003f71",
            color:"white" 
        }
    },
    formStyle: {
        minHeight:"8rem",
        marginBottom:"2.5rem",
        display:"flex",
        justifyContent: "flex-start",
        flexDirection: "column",
        "& input ,textarea ,.MuiFormHelperText-root":{
            fontSize:common.FONT_SIZE.mainText,
        },
        "& td": {
            width:"260px"
        },
        "& #remark":{
            padding:5,
            lineHeight:1.2
        },
        "& .MuiOutlinedInput-multiline":{
            padding:"12px 14px"
        }
    },
    labelStyle: {
        paddingRight: "60px"
    }
});



export function AdminUserRegister() {
    // -----定数の定義-----
    //遷移元から受け取る主催者データ
    const location:transitUserValue=useLocation();
    const userValue:User=location.state;


    // -----共通関数の宣言-----
    const {
        go // 画面遷移 
    } = common.useCommon();


    
 
    // -----Handler-----
    //登録ボタンクリック時の処理
    const onClickRegister=(url:string)=>{
        //初めてクリックされたら各エラー表示をONにする
        if(!isClicked){
            setIsError({
                name: true,
                nameKana:true,
                phoneNumber:true,
                email:true, 
                password:true,
                checkPassword:true
            } )
            setisClicked(true);
        } 

        if(validateAllForms()) go(getTransitionInfo(url));

    }

    //フォーム入力時の処理
    const handleInput=(event: React.ChangeEvent<HTMLInputElement>)=>{
        const name = event.target.name ;

        setUserStateValue({
            ...userStateValue,
            [name]:event.target.value
        })
        
        switch(name){
            case "name":if(validateUserName(event.target.value)) break; 
                            else if(!isError.name){
                                setIsError({
                                            ...isError,
                                            [name]:true
                                        }); break;
                            }; break;

            case "nameKana":if(validateNameKana(event.target.value)) break; 
                            else if(!isError.nameKana){
                            setIsError({
                                        ...isError,
                                        [name]:true
                                    }); break;
                            }; break;

            case "phoneNumber":const validatePhoneNumResult = common.validatePhoneNumberFunc(event.target.value);
                                if(validatePhoneNumResult.correctFlag) {
                                    setPhoneNumberErrorMessage(validatePhoneNumResult.errorMessage);
                                    break;
                                } 
                                else if(!isError.phoneNumber){
                                setIsError({
                                            ...isError,
                                            [name]:true
                                        }); 
                                };
                                setPhoneNumberErrorMessage(validatePhoneNumResult.errorMessage);
                                break;

            case "email": const validateEmailResult =common.validateEmailFunc(event.target.value);
                        if(validateEmailResult.correctFlag){
                            setEmailErrorMessage(validateEmailResult.errorMessage);
                            break;
                        }  
                        else if(!isError.email){
                        setIsError({
                                    ...isError,
                                    [name]:true
                                }); 
                        }; 
                        setEmailErrorMessage(validateEmailResult.errorMessage);
                        break;

            case "password":validateCheckPassword(checkPasswordValue ,event.target.value);
                            if(validatePassword(event.target.value)) break; 
                            else if(!isError.password){
                            setIsError({
                                        ...isError,
                                        [name]:true
                                    }); break;
                            }; break;

            case "checkPassword":setCheckPasswordValue(event.target.value);
                                if(validateCheckPassword(event.target.value ,userStateValue.password)) break; 
                                else if(!isError.checkPassword){
                                setIsError({
                                            ...isError,
                                            [name]:true
                                        }); break;
            }; break;
        }
    }


    //各項目でフォーカスアウトした時にエラー表示をONにする
    const handleBlur=(event: React.FocusEvent<HTMLInputElement>)=>{
        const name = event.target.name as keyof typeof userStateValue;
        setIsError({
            ...isError,
            [name]:true
        })
    }


    //各項目のバリデーション関数
    const validateUserName=(userName:string)=>{
        if(userName!==""){
            setUserNameErrorMessage("");
            return true;
        }else{
            setUserNameErrorMessage("入力して下さい");
            return false;
        }
    }


    const validateNameKana=(nameKana:string)=>{
        if(nameKana.match(/^[ァ-ヶー　]*$/)&&nameKana!==""){
            setNameKanaErrorMessage("");
            return true;
        }
        else if(nameKana===""){
            setNameKanaErrorMessage("入力して下さい");
            return false;
        }else{
            setNameKanaErrorMessage("全角カナで入力して下さい")
        }
        return false;
    }


    const validatePassword=(password:any)=>{
        if(isValidPassword(password)){
            return true
        }else if(password===""){
            setPasswordErrorMessage("入力して下さい");
        }
        return false
    }

    const validateCheckPassword=(checkPassword:string ,password:string | null)=>{
        if(checkPassword === password){
            if(checkPassword===""){
                setCheckPasswordErrorMessage("入力して下さい");
                return false;
            }else{
                 setCheckPasswordErrorMessage("");
                 return true;
            }
        }else if(checkPassword===""){
            setCheckPasswordErrorMessage("入力して下さい");
        }else{
            setCheckPasswordErrorMessage("パスワードと一致しません");
        }
        return false;
    }


    function isValidPassword(password:string){
        
        const validateResult = common.validatePasswordFunc(password)

        validateResult.correctFlag ? setPasswordErrorMessage(""):setPasswordErrorMessage(validateResult.errorMessage);
        return validateResult.correctFlag;
    }





    //確認ページへの遷移時のJSONデータ返す
    function getTransitionInfo(url:string):transitUserValue{
        const info={ 
            pathname: url,
            state : {   
                id:userStateValue.id,
                name:userStateValue.name ,
                nameKana:userStateValue.nameKana,
                email:userStateValue.email,
                phoneNumber:userStateValue.phoneNumber,
                note:userStateValue.note,
                modified:userStateValue.modified,
                deleteFlag:userStateValue.deleteFlag,
                password:userStateValue.password 
            } 
        } ;
            return info;
    }


    const validateAllForms=()=>{
        let flag = true;

        if(!validateUserName(userStateValue.name)) flag=false;

        if(!validateNameKana(userStateValue.nameKana)) flag=false;

        if(!common.validatePhoneNumberFunc(userStateValue.phoneNumber).correctFlag){
            flag=false;
        }else setPhoneNumberErrorMessage("");

        if(!common.validateEmailFunc(userStateValue.email).correctFlag){
            flag=false;
        }else setEmailErrorMessage("");

        if(!common.validatePasswordFunc(userStateValue.password!==null? userStateValue.password : "").correctFlag){
            flag=false;
        }else setPasswordErrorMessage("");

        if(!validateCheckPassword(checkPasswordValue ,userStateValue.password)) flag=false;

        return flag
       
    }
 

    // -----スタイルの宣言-----
    const classNames = useStyles();

    

    // -----state-----
    const [userStateValue,setUserStateValue]=React.useState<User>(initialUserStateValue)
    const [checkPasswordValue,setCheckPasswordValue]=React.useState("")
    const [userNameErrorMessage,setUserNameErrorMessage]=React.useState("入力して下さい");
    const [nameKanaErrorMessage,setNameKanaErrorMessage]=React.useState("入力して下さい");
    const [phoneNumberErrorMessage,setPhoneNumberErrorMessage]=React.useState("入力して下さい");
    const [emailErrorMessage,setEmailErrorMessage]=React.useState("入力して下さい");
    const [passwordErrorMessage,setPasswordErrorMessage]=React.useState("入力して下さい");
    const [checkPasswordErrorMessage,setCheckPasswordErrorMessage]=React.useState("入力して下さい");
    //エラー表示をONにするかどうかのフラグ
    const [isError,setIsError]=React.useState({
        name: false,
        nameKana:false,
        phoneNumber:false,
        email:false, 
        password:false,
        checkPassword:false
    } )
    //登録、変更ボタンが初めて押されたかどうか
    const [isClicked,setisClicked]=React.useState(false);




    
    React.useEffect(() => {
        if(userValue !== undefined) setUserStateValue(userValue);
    }, []);



    return (
        <Layout navLinks={normalLinks} buttons={
            [
              { text: goExamRegister.text, handler: () => { go({pathname:goExamRegister.pathname,state:goExamRegister.state })} }
            ]
          }>
            <div className={classNames.adminUserRegisterRoot}>

                <h4>主催者ユーザ 登録</h4>

                <table>
                    <tbody>
                        <tr className={classNames.formStyle}>
                            <th className={classNames.labelStyle}><label>氏名</label></th>
                            <td><TextField style={{width:"200px"}} fullWidth value={userStateValue.name} name="name" onChange={handleInput}  onBlur={handleBlur}
                            error={isError.name ?userNameErrorMessage!="":false} helperText={isError.name ? userNameErrorMessage : "" }/></td>
                        </tr>

                        <tr className={classNames.formStyle}>
                            <th><label>フリガナ</label></th>
                            <td><TextField style={{width:"200px"}} fullWidth value={userStateValue.nameKana} name="nameKana" onChange={handleInput}  onBlur={handleBlur}
                            error={isError.nameKana ?nameKanaErrorMessage!="":false} helperText={isError.nameKana ? nameKanaErrorMessage : "" }/></td>
                        </tr>

                        <tr className={classNames.formStyle}>
                            <th><label>電話番号</label></th>
                            <td><TextField style={{width:"150px"}} fullWidth value={userStateValue.phoneNumber} name="phoneNumber" onChange={handleInput}  onBlur={handleBlur}
                            error={isError.phoneNumber ?phoneNumberErrorMessage!="":false} helperText={isError.phoneNumber ? phoneNumberErrorMessage : "" }/></td>
                        </tr>

                        <tr className={classNames.formStyle}>
                            <th><label>メールアドレス</label></th>
                            <td><TextField fullWidth value={userStateValue.email} name="email" onChange={handleInput}  onBlur={handleBlur}
                            error={isError.email ?emailErrorMessage!="":false} helperText={isError.email ? emailErrorMessage : "" }/></td>
                        </tr>

                        <tr className={classNames.formStyle}>
                            <th><label>パスワード</label></th>
                            <td><TextField fullWidth value={userStateValue.password} name="password"   onBlur={handleBlur}
                            error={isError.password ?passwordErrorMessage!="":false}  
                            helperText={isError.password ? passwordErrorMessage.split("\n").map((line, key) => <span key={key}>{line}<br /></span>) : "" }
                            onChange={handleInput} /></td>
                        </tr>

                        <tr className={classNames.formStyle}>
                            <th className={classNames.labelStyle}><label>パスワード（確認用）</label></th>
                            <td><TextField fullWidth value={checkPasswordValue} name="checkPassword"  onBlur={handleBlur}
                            error={isError.checkPassword ?checkPasswordErrorMessage!="":false} helperText={isError.checkPassword ? checkPasswordErrorMessage : "" }
                            onChange={handleInput} /></td>
                        </tr>

                        <tr className={classNames.formStyle}>
                            <th><label>備考-(任意)</label></th>
                            <td><TextField id="remark" fullWidth value={userStateValue.note} name="note" onChange={handleInput}
                                variant="outlined"  multiline rows={3} /></td>
                        </tr>
                    </tbody>
                </table>

                <ButtonContainer style={{marginTop:"50px"}} className={classNames.buttonStyle}>
                    <DefaultBackButton className="backBtn" onClick={() => { go(getTransitionInfo("/admin-user/index")) }}>戻る</DefaultBackButton>
                    <DefaultButton  onClick={() => { onClickRegister("/admin-user/confirm/register/0") }}>入力内容を確認する</DefaultButton>
                </ButtonContainer>
                
            </div>
        </Layout>
    );
}
